import React, { useEffect, useState } from "react";
import {
  ArrowSmRightIcon,
  PencilAltIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FolderIcon,
  FolderOpenIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import SideMenu from "../component/SideMenu";
import RecursiveCategories from "../component/RecursiveCategories";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import moment from "moment";
import {
  downloadDocument,
  populateCategories,
  documentStatuses,
  languages,
  fileExtention,
} from "../libs/common";
import Loader from "../component/Loader";
import { getCurrentUser, isUserAllowedToEdit } from "../App";
import { useAlert } from "react-alert";
import CaseSelectAsync from "../component/CaseSelectAsync";
import { data } from "autoprefixer";

function getLastDateInput() {
  let dod = localStorage.getItem(`LAST_DOD_INPUT`);
  if (dod) {
    const dodMoment = moment(dod);
    if (dodMoment.isValid()) {
      return dodMoment.format("YYYY-MM-DD");
    }
  }

  return moment().format("YYYY-MM-DD");
}
function saveLastDateInput(date) {
  if (!moment(date).isValid()) return;
  localStorage.setItem("LAST_DOD_INPUT", date);
}

const initCategory = {
  parent_id: null,
  category_name: "",
  description: "",
  visibility: "",
};

const userLogged = JSON.parse(localStorage.getItem("HPRP_DOCUMENT_USER"));
const initDocument = {
  category_id: 1,
  document_title: "",
  document_briefs: "",
  document_status: { value: "executed", label: "Executed" },
  file: null,
  uploader: userLogged?.user_code,
  visible: 1,
  uploader_phone: userLogged?.call_phone_number,
  uploader_email: userLogged?.email,
  date_of_document: getLastDateInput(),
  author: "",
  visibility: "NORMAL",
  user_codes: [],
};
const Documents = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [category, setCategory] = useState([]);
  const [rawCategories, setRawCategories] = useState([]);
  const [, update] = useState({});
  const [dataCategory, setDataCategory] = useState({
    ...initCategory,
    date_of_document: getLastDateInput(),
  });
  const [rawObject, setRawObject] = useState([]);
  const [actionType, setActionType] = useState([]);
  const [practiceGroup, setPracticeGroup] = useState([]);
  const [industrySector, setIndustrySector] = useState([]);

  const [dataDocument, setDataDocument] = useState(initDocument);
  const [recentDownloads, setRecentDownloads] = useState([]);
  const [loadingRecentDownloads, setLoadingRecentDownloads] = useState(false);
  const [lastUploaded, setLastUploaded] = useState([]);
  const [loadingRecentUploaded, setLoadingRecentUploaded] = useState(false);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [loadingRecentUpdated, setLoadingRecentUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const [linkToCase, setLinkToCase] = useState(false);

  const [saving, setSaving] = useState(false);

  const Alert = useAlert();

  const saveDocument = async () => {
    //start validation on saving document
    let errorMsg = {};
    if (!dataDocument.category_id)
      errorMsg.category_id = "category can't be null";
    if (!dataDocument.document_title)
      errorMsg.document_title = "title can't be null";
    if (!dataDocument.document_briefs)
      errorMsg.document_briefs = "brief can't be null";
    if (!dataDocument.document_file)
      errorMsg.document_file = "file can't be null";
    if (!dataDocument.visibility)
      errorMsg.visibility = "visibility can't be null";
    if (!dataDocument.author) errorMsg.author = "author can't be null";
    if (dataDocument.object_id?.length < 1 || !dataDocument.object_id)
      errorMsg.object_id = "Object can't be null";
    if (
      dataDocument.practice_group_id?.length < 1 ||
      !dataDocument.practice_group_id
    )
      errorMsg.practice_group_id = "Practice Group can't be null";
    if (dataDocument.action_type_id?.length < 1 || !dataDocument.action_type_id)
      errorMsg.action_type_id = "Type of agreement can't be null";
    if (
      dataDocument.industry_sector_id?.length < 1 ||
      !dataDocument.industry_sector_id
    )
      errorMsg.industry_sector_id = "industry sector can't be null";

    if (Object.keys(errorMsg).length > 0) {
      setErrorMessage(errorMsg);
      return;
    }
    //end validation on saving document

    //set data to formdata for sending to backend
    let form = new FormData();
    let only_user = [];
    dataDocument.only_user?.map((x) => {
      only_user.push(x.value);
    });

    setSaving(true);
    form.append("category_id", dataDocument.category_id);
    form.append("document_title", dataDocument.document_title);
    form.append("document_briefs", dataDocument.document_briefs);
    form.append("document_file", dataDocument.document_file);
    form.append("uploader", dataDocument.uploader);
    form.append("visible", dataDocument.visible);
    form.append("uploader_phone", dataDocument.uploader_phone);
    form.append("uploader_email", dataDocument.uploader_email);
    form.append("visibility", dataDocument.visibility);
    form.append("only_user", only_user);
    form.append("date_of_document", dataDocument.date_of_document);
    form.append("author", dataDocument.author);

    form.append("language", dataDocument.language?.value ?? null);
    form.append("document_status", dataDocument.document_status?.value ?? null);
    form.append("lms_matter_id", dataDocument.lms_matter_id ?? 0);
    form.append("lms_case_number", dataDocument.lms_case_number ?? "");
    form.append("lms_client_id", dataDocument.lms_client_id ?? 0);
    form.append("lms_client_name", dataDocument.lms_client_name ?? "");
    form.append("lms_case_name", dataDocument.lms_case_name ?? "");
    form.append(
      "lms_originating_user_code",
      dataDocument.lms_originating_user_code ?? ""
    );
    form.append(
      "lms_originating_user_name",
      dataDocument.lms_originating_user_name ?? ""
    );
    form.append("link_to_case", linkToCase ? 1 : 0);

    console.log(dataDocument);
    dataDocument.object_id.forEach((x) => form.append("object_id[]", x.value));
    dataDocument.object_id.forEach((x) =>
      form.append("object_name[]", x.label)
    );
    dataDocument.practice_group_id.forEach((x) =>
      form.append("practice_group_id[]", x.value)
    );
    dataDocument.industry_sector_id.forEach((x) =>
      form.append("industry_sector_id[]", x.value)
    );
    dataDocument.action_type_id.forEach((x) =>
      form.append("action_type_id[]", x.value)
    );
    dataDocument.action_type_id.forEach((x) =>
      form.append("action_type_name[]", x.label)
    );

    //end of set data
    try {
      //start send data to backend
      const documentSave = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/document/`,
        form,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
            "content-type": "multipart/form-data",
          },
        }
      );
      //end send data

      //start loop data tag for bulk saving
      let tag_names = [];
      dataDocument?.tags?.map((x) => {
        tag_names.push(x.value);
      });

      //end loop
      //start save data tag to backend
      // const updateTag = await axios.post(
      // 	`${process.env.REACT_APP_SERVER_URL}/tags/put-tag/`,
      // 	{
      // 		tag_names,
      // 		document_id: documentSave.data.id,
      // 	},
      // 	{
      // 		headers: {
      // 			token: localStorage.getItem(
      // 				"HPRP_DOCUMENT_TOKEN"
      // 			),
      // 		},
      // 	}
      // );

      //end save
      // setDocuments(getDocuments.data);

      Alert.show("Data berhasil disimpan!");
      setTimeout(() => {
        setShowModal(false);
      }, 1000); //set modal invisible
      loadCategories(); //load document data
      loadRecentUpdated(); //load recent update data
      loadRecentUploads(); // load recent uploads data
      setErrorMessage({}); //set error message clean

      // console.log(documentSave.data);
      setShowModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  // load data category
  const loadCategories = async () => {
    try {
      // get data from backend
      const getCategory = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document-category`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getCategory.data.rows.sort((a, b) =>
          a.category_name.localeCompare(b.category_name)
        );
      } catch (error) {
        console.log(error);
      }
      const categories = populateCategories(getCategory.data.rows); //populate categories child and parent
      setRawCategories(getCategory.data.rows); //set data from backend to state populate
      setCategory(categories); // set data category
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  // load data object
  const loadObject = async () => {
    try {
      // get data from backend
      const getObject = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/object`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      let values = [];
      try {
        getObject?.data?.rows?.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      }
      getObject?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setRawObject(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Action Type
  const loadActionType = async () => {
    try {
      // get data from backend
      const getActionType = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/action-type`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getActionType?.data?.rows?.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getActionType?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setActionType(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Practice Group
  const loadPracticeGroup = async () => {
    try {
      // get data from backend
      const getPracticeGroup = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/practice-group`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getPracticeGroup?.data?.rows?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getPracticeGroup?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setPracticeGroup(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Industry Sector
  const loadIndustrySector = async () => {
    try {
      // get data from backend
      const getIndustrySector = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/industry-sector`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getIndustrySector?.data?.rows?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getIndustrySector?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setIndustrySector(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load recent downloads
  const loadRecentDownloads = async () => {
    try {
      setLoadingRecentDownloads(true);
      const getLastDownloads = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/last-download/3`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      let grp = 1;
      setRecentDownloads(getLastDownloads.data);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoadingRecentDownloads(false);
      }, 1000);
    }
  };

  // load recent uploads
  const loadRecentUploads = async () => {
    try {
      setLoadingRecentUploaded(true);
      const getLastUploads = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/last-upload/15`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setLastUploaded(getLastUploads.data);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoadingRecentUploaded(false);
      }, 1000);
    }
  };
  // load recent update
  const loadRecentUpdated = async () => {
    try {
      setLoadingRecentUpdated(true);
      const getLastUpdates = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/last-update/3`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setLastUpdated(getLastUpdates.data);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoadingRecentUpdated(false);
      }, 1000);
    }
  };

  const setAutoFillPractice = (data) => {
    if (data?.practice) {
      const practice = practiceGroup.filter((row) => {
        return row.name == data.practice.practice_name
      })

      if (practice.length > 0) {
        const value = {
          label: practice.name,
          value: practice.id,
        }

        setDataDocument({
          ...dataDocument,
          practice_group_id: value,
        });
      }
    }
  }

  const setAutoFillSector = (data) => {
    if (data?.sector) {
      const sector = industrySector.filter((row) => {
        return row.name == data.sector.sector_name
      })

      if (sector.length > 0) {
        const value = {
          label: sector.name,
          value: sector.id,
        }

        setDataDocument({
          ...dataDocument,
          industry_sector_id: value,
        });
      }
    }
  }
  useEffect(() => {
    loadObject();
    loadActionType();
    loadPracticeGroup();
    loadIndustrySector();

    loadCategories();
    // loadRecentDownloads();
    loadRecentUploads();
    // loadRecentUpdated();
    // loadTags();
    return () => {};
  }, []);

  // useEffect(() => {
  //   setCategory(category);
  //   console.log(category);
  // }, [category]);

  return (
    <div>
      {/* <div className="grid grid-flow-col grid-cols-10 gap-4"> */}
      <div className="flex flex-col md:flex-row">
        {/* call sidebar */}
        <div className="basis-1/4 px-2">
          <SideMenu categories={category} />
        </div>
        {/* end sidebar */}
        <div className="basis-3/4 px-2">
          <div className="row-span-1 col-span-8 border-solid border-2 pt-2 pl-4 pr-4 pb-2 border-gray-100 rounded-lg">
            <div className=" grid sm:grid-cols-1 gap-6">
              <div>
                <span className="text-m font-bold">
                  Last Upload
                  {loadingRecentUploaded && <Loader notext inline={true} />}{" "}
                </span>
                {!loadingRecentUploaded && lastUploaded.length < 1 && (
                  <div className="cursor-pointer hover:bg-gray-100 pt-2 pl-2">
                    <div className="my-4 text-limit-3">
                      <span className="font-medium italic">
                        -- No Recent Download --
                      </span>
                    </div>
                    <hr />
                  </div>
                )}
                {lastUploaded.map((x, i) => {
                  let lang = null;
                  let style = "bg-purple-800";
                  let ext = fileExtention(x.file);
                  let ext_style = "";
                  switch (x.language) {
                    case "id":
                      lang = "ID";
                      style = "bg-green-600";
                      break;
                    case "en":
                      lang = "ENG";
                      style = "bg-sky-400";
                      break;
                    case "multi":
                      lang = "Bilingual";
                      style = "bg-purple-800";
                      break;
                    case "other":
                      lang = "Other";
                      style = "bg-slate-800";
                      break;
                    default:
                  }
                  switch (ext) {
                    case "PDF":
                      ext_style = "bg-red-600";
                      break;
                    case "MS WORD":
                      ext_style = "bg-blue-600";
                      break;
                    case "PPT":
                      ext_style = "bg-orange-500";
                      break;
                    default:
                  }
                  return (
                  <div
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={(e) => {
                      navigate(`/document/${x.id}`);
                    }}
                  >
                    <div className="my-2 text-limit-3">
                      <span className="font-small">{x.document_title}</span>
                      <br />
                      <span className="text-sm">
                        Diunggah pada{" "}
                        {moment(x.created_at).format("DD MMM, HH:mm")}
                      </span>
                      {x.file && x.file !== '' &&
                        <span className={`float-right rounded ${ext_style} p-1 ml-1 text-white font-bold text-xs`}>{ext}</span>
                      }
                      {x.language && x.language !== '' && 
                        <span className={`float-right rounded ${style} p-1 text-white font-bold text-xs`}>{lang}</span>
                      }
                      <div className="clear-left"></div>
                    </div>
                    <hr className="m-0" />
                  </div>
                )
                })}
              </div>
            </div>
          </div>
          {/* <div className="row-span-2 col-span-8 mb-24 sm:mb-2">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Bidang Usaha
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {category &&
                    category.map((row, index) => (
                      <RecursiveCategories
                        row={row}
                        update={update}
                        category={category}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
      {showModal ? (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center flex bg-black/50">
          <div className="relative p-4 w-full max-w-1xl h-full ">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Add Document
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      <input
                        type="checkbox"
                        checked={linkToCase}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setLinkToCase(e.target.checked);
                          if (!e.target.checked) {
                            setDataDocument({
                              lms_matter_id: null,
                              lms_case_number: null,
                              lms_client_id: null,
                              lms_client_name: null,
                              lms_case_name: null,
                              lms_originating_user_code: null,
                              lms_originating_user_name: null,
                            });
                          }
                        }}
                      />{" "}
                      Link to Case
                    </label>
                    {linkToCase && (
                      <CaseSelectAsync
                        onCaseSelected={(e) => {
                          // console.log(e);
                          const caseData = {
                            lms_matter_id: e.data.contract_id,
                            lms_case_number: e.data.contract_number,
                            lms_client_id: e.data.client.client_id,
                            lms_client_name: e.data.client.client_name,
                            lms_case_name: e.data.contract_name,
                            lms_originating_user_code: e.data.originating_user_data.user_code,
                            lms_originating_user_name: `${e.data.originating_user_data.first_name} ${e.data.originating_user_data.middle_name} ${e.data.originating_user_data.last_name}`,
                          };
                          setDataDocument({
                            ...dataDocument,
                            ...caseData,
                            document_title: `(${e.data.contract_number}) `,
                          });

                          // set practice && sector
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Document Type
                      {errorMessage?.category_id && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.category_id}
                        </span>
                      )}
                    </label>
                    <select
                      onChange={(e) => {
                        setDataDocument({
                          ...dataDocument,
                          category_id: e.target.value,
                        });
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                    >
                      {rawCategories.map((row) => (
                        <option value={row.id}>{row.category_name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      File Name
                      {errorMessage?.document_title && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.document_title}
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      value={dataDocument?.document_title}
                      onChange={(e) => {
                        setDataDocument({
                          ...dataDocument,
                          document_title: e.target.value,
                        });
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        (errorMessage?.document_title
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Date of Document
                      {errorMessage?.date_of_document && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.date_of_document}
                        </span>
                      )}
                    </label>
                    <input
                      type="date"
                      value={dataDocument.date_of_document}
                      onChange={(e) => {
                        setDataDocument({
                          ...dataDocument,
                          date_of_document: e.target.value,
                        });
                        saveLastDateInput(e.target.value);
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        (errorMessage?.date_of_document
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                    />
                  </div>
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Author
                      {errorMessage?.author && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.author}
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setDataDocument({
                          ...dataDocument,
                          author: e.target.value,
                        });
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        (errorMessage?.author
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Practice Group
                      {errorMessage?.practice_group_id && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.practice_group_id}
                        </span>
                      )}
                    </label>
                    <Select
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.practice_group_id
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      isMulti
                      options={practiceGroup}
                      placeholder={`Input Practice Group`}
                      components={{
                        DropdownIndicator: null,
                      }}
                      value={dataDocument.practice_group_id}
                      onChange={(values, actionMeta) => {
                        values.sort((a, b) =>
                          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                        );
                        setDataDocument({
                          ...dataDocument,
                          practice_group_id: values,
                        });
                      }}
                    />
                  </div>

                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Industry Sector
                      {errorMessage?.industry_sector_id && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.industry_sector_id}
                        </span>
                      )}
                    </label>
                    <Select
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.industry_sector_id
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      isMulti
                      options={industrySector}
                      placeholder={`Input Industry Sector`}
                      components={{
                        DropdownIndicator: null,
                      }}
                      value={dataDocument.industry_sector_id}
                      onChange={(values, actionMeta) => {
                        values.sort((a, b) =>
                          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                        );
                        setDataDocument({
                          ...dataDocument,
                          industry_sector_id: values,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Object
                      {errorMessage?.object_id && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.object_id}
                        </span>
                      )}
                    </label>
                    <Select
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.object_id
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      isMulti
                      options={rawObject}
                      placeholder={`Input Object`}
                      components={{
                        DropdownIndicator: null,
                      }}
                      value={
                        dataDocument.object_id ? dataDocument.object_id : ""
                      }
                      onChange={(values, actionMeta) => {
                        values.sort((a, b) =>
                          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                        );
                        setDataDocument({
                          ...dataDocument,
                          object_id: values,
                        });
                      }}
                    />
                  </div>

                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Action Type
                      {errorMessage?.action_type_id && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.action_type_id}
                        </span>
                      )}
                    </label>
                    <Select
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.action_type_id
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      isMulti
                      options={actionType}
                      placeholder={`Input Action Type`}
                      components={{
                        DropdownIndicator: null,
                      }}
                      // value={dataDocument.action_type_id}
                      onChange={(values, actionMeta) => {
                        values.sort((a, b) =>
                          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                        );
                        setDataDocument({
                          ...dataDocument,
                          action_type_id: values,
                        });
                      }}
                    />
                  </div>
                </div>

                {console.log(dataDocument.action_type_id)}

                <div className="mb-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Additional Information
                    {errorMessage?.document_briefs && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.document_briefs}
                      </span>
                    )}
                  </label>
                  <textarea
                    placeholder="(Note: Please insert/input the keywords you feel necessary to make searching easier)"
                    rows="4"
                    onChange={(e) => {
                      setDataDocument({
                        ...dataDocument,
                        document_briefs: e.target.value,
                      });
                    }}
                    className={
                      "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                      (errorMessage?.document_briefs
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                  ></textarea>
                </div>

                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      Document Status
                    </label>
                    <Select
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.action_type_id
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      options={documentStatuses}
                      placeholder={`Select Document Status`}
                      components={{
                        DropdownIndicator: null,
                      }}
                      value={dataDocument.document_status}
                      onChange={(values, actionMeta) => {
                        setDataDocument({
                          ...dataDocument,
                          document_status: values,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      Language
                    </label>
                    <Select
                      isMulti={false}
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.action_type_id
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      options={languages}
                      placeholder={`Select Language`}
                      components={{
                        DropdownIndicator: null,
                      }}
                      value={dataDocument.language}
                      onChange={(values, actionMeta) => {
                        setDataDocument({
                          ...dataDocument,
                          language: values,
                        });
                      }}
                    />
                  </div>
                  {/* <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      <input
                        type="checkbox"
                        checked={linkToCase}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          setLinkToCase(e.target.checked);
                          if (!e.target.checked) {
                            setDataDocument({
                              lms_matter_id: null,
                              lms_case_number: null,
                              lms_client_id: null,
                              lms_client_name: null,
                              lms_case_name: null,
                              lms_originating_user_code: null,
                              lms_originating_user_name: null,
                            });
                          }
                        }}
                      />{" "}
                      Link to Case
                    </label>
                    {linkToCase && (
                      <CaseSelectAsync
                        onCaseSelected={(e) => {
                          // console.log(e);
                          const caseData = {
                            lms_matter_id: e.data.contract_id,
                            lms_case_number: e.data.contract_number,
                            lms_client_id: e.data.client.client_id,
                            lms_client_name: e.data.client.client_name,
                            lms_case_name: e.data.contract_name,
                            lms_originating_user_code:
                              e.data.originating_user_data.user_code,
                            lms_originating_user_name: `${e.data.originating_user_data.first_name} ${e.data.originating_user_data.middle_name} ${e.data.originating_user_data.last_name}`,
                          };
                          setDataDocument({
                            ...dataDocument,
                            ...caseData,
                            document_title: `(${e.data.contract_number}) `,
                          });
                          console.log(`document_title: (${e.data.contract_number})`)
                        }}
                      />
                    )}
                  </div> */}
                </div>
                <div className="flex gap-4 flex-col sm:flex-row ">
                  <div className="mb-2 w-full sm:w-1/2">
                    {/* <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                      Tag
                      {errorMessage?.tags && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.tags}
                        </span>
                      )}
                    </label>
                    <CreatableSelect
                      className={
                        "mt-3 border rounded-md " +
                        (errorMessage?.tags
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      isClearable
                      isMulti
                      placeholder={`input Tags`}
                      components={{ DropdownIndicator: null }}
                      value={dataDocument.tags}
                      onChange={(values, actionMeta) => {
                        setDataDocument({ ...dataDocument, tags: values });
                        // console.log(values);
                      }}
                    /> */}
                    {/* </div>
										
										<div className="mb-2 w-full sm:w-1/2"> */}
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Upload file
                      {errorMessage?.document_file && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.document_file}
                        </span>
                      )}
                    </label>
                    <input
                      className={
                        "block w-full text-sm text-gray-900 border rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 " +
                        (errorMessage?.document_title
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      type="file"
                      onChange={(e) => {
                        setDataDocument({
                          ...dataDocument,
                          document_file: e.target.files[0],
                        });
                      }}
                    />
                    {/* <div
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="user_avatar_help"
                    >
                      A profile picture is useful to confirm your are logged
                      into your account
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => {
                    setShowModal(false);
                    setErrorMessage({});
                  }}
                >
                  Close
                </button>
                <button
                  disabled={saving}
                  type="button"
                  className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => {
                    saveDocument();
                  }}
                >
                  {saving ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isUserAllowedToEdit() && (
        <button
          className="z-30 fixed bottom-6 right-6 bg-main py-4 rounded-xl flex items-center px-4 font-medium text-xs  leading-tight  shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
          onClick={(e) => {
            setShowModal(true);
          }}
        >
          <PlusIcon className="w-6 text-white" />
          <div className="text-white font-semibold text-base ml-2">
            Add Document
          </div>
        </button>
      )}
    </div>
  );
};

//
export default Documents;
