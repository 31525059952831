import React from "react";
import {
  FolderIcon,
  FolderOpenIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

export default function RecursiveCategories({
  update,
  row,
  level = 1,
  embed = false,
}) {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          scope="row"
          style={{ paddingLeft: 30 * (level - 1) + 20 + "px" }}
          className={
            "px-3 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap" +
            "flex"
          }
        >
          <div className="flex cursor-pointer">
            {!row.sub_category || row.sub_category.length <= 0 ? (
              <FolderIcon className="w-6 h-6 ml-1 mr-2" />
            ) : (
              <>
                {row.hidesub == true || row.sub_category?.length == 0 ? (
                  <div
                    className="flex"
                    onClick={() => {
                      row.hidesub = !row.hidesub;
                      update({});
                    }}
                  >
                    <ChevronRightIcon className="w-6 h-6" />
                    <FolderIcon className="w-6 h-6 -ml-1 mr-2" />
                  </div>
                ) : (
                  <div
                    className="flex"
                    onClick={() => {
                      row.hidesub = !row.hidesub;
                      update({});
                    }}
                  >
                    <ChevronDownIcon className="w-6 h-6" />
                    <FolderOpenIcon className="w-6 h-6 -ml-1 mr-2" />
                  </div>
                )}
              </>
            )}
            <a
              href="#"
              onClick={(e) => {
                if (embed) {
                  navigate("/embed_list?category_id=" + row.id);
                } else {
                  navigate("/list?category_id=" + row.id);
                }
              }}
            >
              {row.category_name}
            </a>
          </div>
        </th>
        <td className="px-2 py-1 font-medium text-gray-900 dark:text-white whitespace-nowrap">
          <button
            type="button"
            className="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 font-medium rounded-lg text-sm px-5 py-2 dark:bg-purple-600 dark:hover:bg-purple-700 "
            onClick={() => {
              if (embed) {
                navigate("/embed_list?category_id=" + row.id);
              } else {
                navigate("/list?category_id=" + row.id);
              }
            }}
          >
            Lihat
          </button>
        </td>
      </tr>
      {row.hidesub == false && row.sub_category?.length > 0 && (
        <>
          {row.sub_category.map((x) => (
            <RecursiveCategories
              row={x}
              update={update}
              level={level + 1}
              embed={embed}
            />
          ))}
        </>
      )}
    </React.Fragment>
  );
}
