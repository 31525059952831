import { DownloadIcon, PencilIcon, PlusIcon, EyeIcon } from "@heroicons/react/outline";
import SideMenu from "./SideMenu";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Tag from "./Tag";
import moment from "moment";
import { downloadDocument, fileExtention, previewDocument } from "../libs/common";
import { isUserAllowedToEdit } from "../App";
import FormEditDocument from "./FormEditDocument";

const Detail = (props) => {
  const navigate = useNavigate();

  const [documents, setDocuments] = useState();
  const [showModal, setShowModal] = useState(false);

  let location = useLocation();
  let uri = location.pathname.split("/");
  let id = uri[uri.length - 1];
  const test = [1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  const dateFormat = (date) => {
    const dates = new Date(date);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return dates.toLocaleDateString(["ban", "id"], options);
  };
  const loadDocument = async () => {
    try {
      const getDocuments = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/${id}`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setDocuments(getDocuments.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    loadDocument();
    return () => {};
  }, []);
  let lang = "N/A";
  switch (documents?.language) {
    case "id":
      lang = "Indonesian";
      break;
    case "en":
      lang = "English";
      break;
    default:
  }
  function getExt(file) {
    let ext = "";

    if (file) {
      const findExt = file.split(".");
      ext = findExt[findExt.length - 1];
    }

    return ext;
  }
  return (
    <>
      <div className=" p-4 border-b border-gray-200 w-full">
        <div className="text-4xl font-semibold text-black">
          Informasi Dokumen
        </div>
      </div>
      <div className="flex flex-row mt-4 justify-center">
        {/* <button
          type="button"
          className=" absolute bottom-6 right-6 bg-main py-4 rounded-xl inline-block px-6 font-medium text-xs leading-tight uppercase shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalScrollable"
        >
          <PencilAltIcon className="w-6 text-white" />
        </button> */}

        {/* <div className="basis-1/4">
          <SideMenu />
        </div> */}

        <div className="basis-2/3 flex flex-row">
          <div class="overflow-x-auto relative shadow-md sm:rounded-lg basis-full">
            <div className="p-8 border-b border-gray-200 w-full">
              <div className="text-xl font-semibold text-black">
                {documents?.document_title}
              </div>          
            </div>
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Document Title
                  </th>
                  <td class="py-4 px-6">{documents?.document_title}</td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Document Type
                  </th>
                  <td class="py-4 px-6">
                    {documents?.category?.category_name ?? "-"}
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Date of Document
                  </th>
                  <td class="py-4 px-6">
                    {moment(documents?.date_of_document).format("DD/MM/YYYY")}
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Author
                  </th>
                  <td class="py-4 px-6">{documents?.author}</td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Language
                  </th>
                  <td class="py-4 px-6">{lang}</td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Practice Group
                  </th>
                  <td class="py-4 px-6">
                    <ul>
                      {documents?.practice_group?.map((x) => (
                        <li>{x.practice_group_name?.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Industry Sector
                  </th>
                  <td class="py-4 px-6">
                    <ul>
                      {documents?.industry_sector?.map((x) => (
                        <li>{x.industry_sector_name?.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Object
                  </th>
                  <td class="py-4 px-6">
                    <ul>
                      {documents?.object?.map((x) => (
                        <li>{x.object_name?.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Action Type
                  </th>
                  <td class="py-4 px-6">
                    <ul>
                      {documents?.action_type?.map((x) => (
                        <li>{x.action_type_name?.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Additional Information
                  </th>
                  <td class="py-4 px-6">{documents?.document_briefs}</td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Tags
                  </th>
                  <td class="py-4 px-6">
                    <ul>
                      {documents?.tags?.map((x) => (
                        <li>{x.tag}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
                <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    Download
                  </th>
                  <td class="py-4 px-6">
                    <button
                      className="bg-main py-4 rounded-xl flex items-center px-4 font-medium text-xs  leading-tight  shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
                      onClick={(e) => {
                        downloadDocument(documents.id, null, documents);
                      }}
                    >
                      <DownloadIcon className="w-6 text-white" />
                      <div className="text-white font-semibold text-base ml-2">
                        Download Document
                      </div>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="basis-1/3 p-4">          
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <tr>
              <td>
                { getExt(documents?.file) === "pdf"
                  ? <button
                      style={{ background: "#6f3091" }}
                      onClick={(e) =>
                        previewDocument(documents.id, null, documents)
                      }
                      className="bg-main py-4 text-white rounded-xl flex items-center px-4 font-medium text-xs  leading-tight  shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
                    >
                      <EyeIcon className="w-6 text-white" />
                      &nbsp;&nbsp;Preview Document
                    </button>
                  : ""
                }                
              </td>
            </tr>
          </table>

          <div className="p-4 border-b border-gray-200 w-full">
            <div className="text-xl font-semibold text-black">History</div>
          </div>
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="py-3 px-6">
                  Tanggal Revisi
                </th>
                <th scope="col" class="py-3 px-6">
                  Remark
                </th>
                <th scope="col" class="py-3 px-6">
                  Date
                </th>
                <th scope="col" class="py-3 px-6">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {documents?.revisions
                ?.sort((a, b) =>
                  moment(a.revision_date).diff(b.revision_date, "second") > 1
                    ? -1
                    : 1
                )
                .map((x) => (
                  <tr class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {moment(x.revision_date).format("ddd, DD MMM YYYY")}
                    </th>
                    <th
                      scope="row"
                      class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {x.changelogs}
                    </th>
                    <th
                      scope="row"
                      class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {moment(x.created_at).format("DD MMM YYYY")}
                    </th>
                    <td class="py-4 px-6">
                      { getExt(documents?.file) === "pdf" 
                        ? <button
                            style={{ background: "#6f3091" }}
                            onClick={(e) =>
                              previewDocument(documents.id, x.id, documents)
                            }
                            className="bg-gray-100 text-white border-gray-100 border-2 rounded border-solid p-1"
                          >
                            Preview
                          </button>
                        : ""
                      }                      
                      <button
                        style={{ background: "#6f3091" }}
                        onClick={(e) =>
                          downloadDocument(documents.id, x.id, documents)
                        }
                        className="bg-gray-100 text-white border-gray-100 border-2 rounded border-solid p-1"
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-row justify-center"></div>
      {isUserAllowedToEdit() && (
        <button
          className="z-30 fixed bottom-6 right-6 bg-main py-4 rounded-xl flex items-center px-4 font-medium text-xs  leading-tight  shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
          onClick={(e) => {
            setShowModal(true);
          }}
        >
          <PencilIcon className="w-6 text-white" />
          <div className="text-white font-semibold text-base ml-2">
            Edit Document
          </div>
        </button>
      )}
      {documents && (
        <FormEditDocument
          showModal={showModal}
          setShowModal={setShowModal}
          document={documents}
          onDocumentUpdated={() => {
            loadDocument();
          }}
        />
      )}
    </>
  );
};

export default Detail;
