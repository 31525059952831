export default function Tag({ onClick, tag_name, count = null }) {
	return (
		<div
			onClick={(e) => {
				if (onClick) onClick();
			}}
			class="mr-2 mb-2 cursor-pointer text-xs inline-flex items-center font-bold leading-sm uppercase pl-3  bg-purple-200 text-purple-800 rounded-full"
		>
			{tag_name}
			<div className="ml-1 bg-purple-500 py-1 px-3 rounded-full text-white">{count ? count : " "}</div>
		</div>
	);
}
