import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import RecursiveCategories from "../component/RecursiveCategories";
import { populateCategories } from "../libs/common";
const EmbedIframe = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [, update] = useState({});
  // load data category
  const loadCategories = async () => {
    try {
      // get data from backend
      const getCategory = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document-category`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );

      const categories = populateCategories(getCategory.data.rows); //populate categories child and parent
      setCategory(categories); // set data category
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadCategories();
    // loadTags();
    return () => {};
  }, []);

  // useEffect(() => {
  //   setCategory(category);
  //   console.log(category);
  // }, [category]);

  return (
    <div className="flex justify-center">
      {/* <div className="grid grid-flow-col grid-cols-10 gap-4"> */}
      <div className=" px-2" style={{ width: "600px", height: "600px" }}>
        <div className="row-span-2 col-span-8 mb-24 sm:mb-2">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Bidang Usaha
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {category &&
                  category.map((row, index) => (
                    <RecursiveCategories
                      row={row}
                      update={update}
                      category={category}
                      embed={true}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

//
export default EmbedIframe;
