export default function Loader({ notext, inline }) {
  return (
    <div
      className={
        "w-20 " +
        (inline ? "inline-block align-middle" : "flex flex-row items-center")
      }
    >
      <img className="w-10" src="/spinner.gif" alt="" />

      {!notext && <div className="flex-1">Loading...</div>}
    </div>
  );
}
