import logo from "./logo.svg";
import "./App.css";
import React, { Component } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./component/Header";
import Login from "./content/Login";
import Detail from "./component/Detail";
import Documents from "./content/Documents.js";
import ListDocuments from "./component/ListDocuments";
import Category from "./content/Category";
import axios from "axios";
import Permission from "./content/Permission";
import EmbedIframe from "./content/EmbedIframe";
import EmbedList from "./content/EmbedList";
import DocumentObject from "./content/Obejct";
import ActionType from "./content/ActionType";
import IndustrySector from "./content/IndustrySector";
import PracticeGroup from "./content/PracticeGroup";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import EloDetail from "./content/EloDetail";

const options = {
	// you can also just use 'bottom center'
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	offset: "30px",
	// you can also just use 'scale'
	transition: transitions.SCALE,
};

export function isUserAllowedToEdit() {
	return getCurrentUser()?.permission_setting?.allow_edit ?? 0 == 1;
}
export function isUserAllowedToDownload() {
	return getCurrentUser()?.permission_setting?.allow_download ?? 0 == 1;
}
export function getCurrentUser() {
	let userData = localStorage.getItem("HPRP_DOCUMENT_USER");
	if (!userData) {
		return null;
	} else {
		try {
			return JSON.parse(userData);
		} catch (error) {
			console.log(error);
			return null;
		}
	}
}

function App() {
	const navigate = useNavigate(); // Automatically append token to headers
	const HPRP_DOCUMENT_TOKEN = localStorage.getItem("HPRP_DOCUMENT_TOKEN");	

	axios.interceptors.request.use((config) => {	
		config.headers.token = HPRP_DOCUMENT_TOKEN;
		return config;
	});
	// Automatically navigate to /login if response status returned 401
	axios.interceptors.response.use(
		(response) => {	
			return response;
		},
		(error) => {
			const skip = ["/login"];
			for (let uri of skip) {
				if ((error?.response?.config?.url ?? "").indexOf(uri) >= 0) {
					throw error;
				}
			}
			console.log(error);
			if (error.response && error.response.status == 401) {
				navigate("/login", { replace: true });
			}						
			throw error;
		}
	);

	return (
		<AlertProvider template={AlertTemplate} {...options}>
			<Header />
			<div className="container mx-auto mt-4">
				<Routes>
					<Route exact path="/" element={<Documents />} />
					<Route path="/login" element={<Login />} />
					<Route path="/category" element={<Category />} />
					<Route path="/list/" element={<ListDocuments />} />
					<Route path="/document/object" element={<DocumentObject />} />
					<Route path="/document/action-type" element={<ActionType />} />
					<Route path="/document/practice-group" element={<PracticeGroup />} />
					<Route path="/document/industry-sector" element={<IndustrySector />} />
					<Route path="/document/:id" element={<Detail />} />
					<Route exact path="/permission" element={<Permission />} />
					<Route exact path="/embed" element={<EmbedIframe />} />
					<Route exact path="/embed_list" element={<EmbedList />} />
					<Route exact path="/elo" element={<EloDetail />} />
				</Routes>
			</div>
		</AlertProvider>
	);
}
export default App;
