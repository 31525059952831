import {
  ClockIcon,
  DocumentDownloadIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import Select from "react-select";
import {
  downloadDocument,
  populateCategories,
  documentStatuses,
  languages,
} from "../libs/common";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import CaseSelectAsync from "./CaseSelectAsync";

export default function FormEditDocument({
  showModal,
  setShowModal,
  onDocumentUpdated,
  document,
}) {
  const [errorMessage, setErrorMessage] = useState([]);
  const [saving, setSaving] = useState(false);
  const [dataDocument, setDataDocument] = useState(document);

  const [editTab, setEditTab] = useState("Edit");
  const [oldTag, setOldTag] = useState(null);

  const [tab, setTab] = useState(["Edit"]);
  const [rawCategories, setRawCategories] = useState([]);
  const [practiceGroup, setPracticeGroup] = useState([]);
  const [industrySector, setIndustrySector] = useState([]);
  const [rawObject, setRawObject] = useState([]);
  const [actionType, setActionType] = useState([]);
  const [linkToCase, setLinkToCase] = useState(false);
  const [language, setLanguage] = useState(languages[0]);

  const [category, setCategory] = useState(null);

  const Alert = useAlert();

  const userLogged = JSON.parse(localStorage.getItem("HPRP_DOCUMENT_USER"));

  useEffect(() => {
    if (!document) return;
    loadCategories();
    loadObject();
    loadActionType();
    loadPracticeGroup();
    loadIndustrySector();

    let tags = [];
    document.date_of_document = moment(document.date_of_document).format(
      "YYYY-MM-DD"
    );
    document.tags?.map((val) => {
      tags.push({
        value: val.tag,
        label: val.tag,
      });
    });

    let object_id = [];
    document.object?.map((val) => {
      object_id.push({
        value: val.object_id,
        label: val.object_name.name,
      });
    });

    let action_type_id = [];
    document.action_type?.map((val) => {
      action_type_id.push({
        value: val.action_type_id,
        label: val.action_type_name.name,
      });
    });

    let industry_sector_id = [];
    document.industry_sector?.map((val) => {
      industry_sector_id.push({
        value: val.industry_sector_id,
        label: val.industry_sector_name.name,
      });
    });

    let practice_group_id = [];
    document.practice_group?.map((val) => {
      practice_group_id.push({
        value: val.practice_group_id,
        label: val.practice_group_name.name,
      });
    });
    const nd = {
      ...document,
      tags,
      user_codes: document.visible_to.map((x) => ({
        value: x.user_code,
        label: x.user_code,
      })),
      object_id,
      practice_group_id,
      industry_sector_id,
      action_type_id,
      language: languages.find((x) => x.value == document.language),
      document_status: documentStatuses.find(
        (x) => x.value == document.document_status
      ),
    };

    // If lms matter id is not empty or 0
    if (document.lms_matter_id) {
      setLinkToCase(true);
    }

    setDataDocument(nd);

    return () => {};
  }, [document]);

  // load data object
  const loadObject = async () => {
    try {
      // get data from backend
      const getObject = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/object`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getObject?.data?.rows?.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getObject?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setRawObject(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Action Type
  const loadActionType = async () => {
    try {
      // get data from backend
      const getActionType = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/action-type`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getActionType?.data?.rows?.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getActionType?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setActionType(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Practice Group
  const loadPracticeGroup = async () => {
    try {
      // get data from backend
      const getPracticeGroup = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/practice-group`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getPracticeGroup?.data?.rows?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getPracticeGroup?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setPracticeGroup(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Industry Sector
  const loadIndustrySector = async () => {
    try {
      // get data from backend
      const getIndustrySector = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/industry-sector`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getIndustrySector?.data?.rows?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getIndustrySector?.data?.rows?.map((x) => {
        values.push({
          label: x.name,
          value: x.id,
        });
      });
      setIndustrySector(values); // set data
    } catch (e) {
      console.log(e);
    }
  };
  const loadCategories = async () => {
    try {
      const getCategory = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document-category`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      try {
        getCategory.data.rows.sort((a, b) =>
          a.category_name.localeCompare(b.category_name)
        );
      } catch (error) {
        console.log(error);
      }
      const categories = populateCategories(getCategory.data.rows);
      setRawCategories(getCategory.data.rows);
      // console.log(categories);
      setCategory(categories);
    } catch (e) {
      console.log(e);
    }
  };

  const updateDocument = async () => {
    let errorMsg = {};
    if (!dataDocument.category_id)
      errorMsg.category_id = "category can't be null";
    if (!dataDocument.document_title)
      errorMsg.document_title = "title can't be null";
    if (!dataDocument.document_briefs)
      errorMsg.document_briefs = "brief can't be null";
    // if (!dataDocument.document_file)
    //   errorMsg.document_file = "file can't be null";
    if (!dataDocument.visibility)
      errorMsg.visibility = "visibility can't be null";
    if (!dataDocument.author) errorMsg.author = "author can't be null";
    if (dataDocument.object_id?.length < 1 || !dataDocument.object_id)
      errorMsg.object_id = "Object can't be null";
    if (
      dataDocument.practice_group_id?.length < 1 ||
      !dataDocument.practice_group_id
    )
      errorMsg.practice_group_id = "Practice Group can't be null";
    if (dataDocument.action_type_id?.length < 1 || !dataDocument.action_type_id)
      errorMsg.action_type_id = "Type of agreement can't be null";
    if (
      dataDocument.industry_sector_id?.length < 1 ||
      !dataDocument.industry_sector_id
    )
      errorMsg.industry_sector_id = "industry sector can't be null";
    if (!dataDocument.remark) errorMsg.remark = "remark harus diisi";
    if (Object.keys(errorMsg).length > 0) {
      setErrorMessage(errorMsg);
      console.log(errorMsg);
      console.log(dataDocument);
      setSaving(false);

      return;
    }
    try {
      setSaving(true);
      let form = new FormData();
      form.append("category_id", dataDocument.category_id);

      form.append("document_title", dataDocument.document_title);
      form.append("document_briefs", dataDocument.document_briefs);
      form.append("uploader", userLogged?.user_code);
      form.append("visible", dataDocument.visible);
      form.append("uploader_phone", userLogged?.call_phone_number);
      form.append("uploader_email", userLogged?.email);
      form.append("visibility", dataDocument.visibility);
      form.append("remark", dataDocument.remark);
      form.append("date_of_document", dataDocument.date_of_document);
      form.append("author", dataDocument.author);

      form.append("lms_matter_id", dataDocument.lms_matter_id ?? 0);
      form.append("lms_case_number", dataDocument.lms_case_number ?? "");
      form.append("lms_client_id", dataDocument.lms_client_id ?? 0);
      form.append("lms_client_name", dataDocument.lms_client_name ?? "");
      form.append("lms_case_name", dataDocument.lms_case_name ?? "");
      form.append("language", dataDocument.language?.value ?? null);
      form.append(
        "document_status",
        dataDocument.document_status?.value ?? null
      );
      form.append(
        "lms_originating_user_code",
        dataDocument.lms_originating_user_code ?? ""
      );
      form.append(
        "lms_originating_user_name",
        dataDocument.lms_originating_user_name ?? ""
      );
      form.append("link_to_case", linkToCase ? 1 : 0);

      dataDocument.object_id.forEach((x) =>
        form.append("object_id[]", x.value)
      );
      dataDocument.object_id.forEach((x) =>
        form.append("object_name[]", x.label)
      );

      dataDocument.practice_group_id.forEach((x) =>
        form.append("practice_group_id[]", x.value)
      );
      dataDocument.industry_sector_id.forEach((x) =>
        form.append("industry_sector_id[]", x.value)
      );
      dataDocument.action_type_id.forEach((x) =>
        form.append("action_type_id[]", x.value)
      );
      dataDocument.action_type_id.forEach((x) =>
        form.append("action_type_name[]", x.label)
      );
      dataDocument.tags.forEach((x) => form.append("tags[]", x.value));
      if (dataDocument.document_file) {
        form.append("document_file", dataDocument.document_file);
      }
      // for (let user of dataDocument.user_codes) {
      //     form.append("user_codes[]", user.value);
      // }
      // console.log(
      // 	"@@@@@@@@@@@@@@@@@@@@@@",
      // 	dataDocument.tags
      // );

      const documentUpdate = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/document/${dataDocument.id}`,
        form,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
            "content-type": "multipart/form-data",
          },
        }
      );

      let tag_names = [];
      dataDocument?.tags?.map((x) => {
        tag_names.push(x.value);
      });

      // const updateTag = await axios.post(
      // 	`${process.env.REACT_APP_SERVER_URL}/tags/put-tag/`,
      // 	{
      // 		tag_names,
      // 		document_id: dataDocument.id,
      // 	},
      // 	{
      // 		headers: {
      // 			token: localStorage.getItem(
      // 				"HPRP_DOCUMENT_TOKEN"
      // 			),
      // 		},
      // 	}
      // );

      Alert.show("Data berhasil disimpan!");
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
      setErrorMessage({});

      onDocumentUpdated();
    } catch (e) {
      console.log(e);
    } finally {
      console.log(`Finally`);
      setSaving(false);
    }
  };

  return showModal ? (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center flex bg-black/50">
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Edit Document
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="p-6 space-y-6">
            <div className="border-b border-gray-200 dark:border-gray-700 mb-2">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                {tab.map((x) => (
                  <li className="mr-2" key={x}>
                    <a
                      onClick={() => {
                        setEditTab(x);
                      }}
                      className={
                        "cursor-pointer " +
                        (editTab == x
                          ? "inline-flex p-4 text-main rounded-t-lg border-b-2 border-main active group"
                          : "inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group")
                      }
                    >
                      {x == "Edit" ? (
                        <PencilAltIcon
                          className={
                            editTab == x
                              ? "mr-2 w-5 h-5 text-main"
                              : "mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          }
                        />
                      ) : x == "Edit History" ? (
                        <ClockIcon
                          className={
                            editTab == x
                              ? "mr-2 w-5 h-5 text-main"
                              : "mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          }
                        />
                      ) : (
                        <DocumentDownloadIcon
                          className={
                            editTab == x
                              ? "mr-2 w-5 h-5 text-main"
                              : "mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                          }
                        />
                      )}
                      {x}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={editTab != "Edit" ? "hidden" : ""}>
              <div className="flex gap-4 flex-col sm:flex-row">
                <div className="mb-2 w-full sm:w-1/2 ">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                    Document Type
                  </label>
                  <select
                    onChange={(e) => {
                      setDataDocument({
                        ...dataDocument,
                        category_id: e.target.value,
                      });
                    }}
                    defaultValue={dataDocument?.category_id}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  >
                    {rawCategories.map((row) => (
                      <option value={row.id}>{row.category_name}</option>
                    ))}
                  </select>
                </div>

                <div className="mb-2 w-full sm:w-1/2 ">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    File Name
                    {errorMessage?.document_title && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.document_title}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    value={dataDocument.document_title}
                    onChange={(e) => {
                      setDataDocument({
                        ...dataDocument,
                        document_title: e.target.value,
                      });
                    }}
                    className={
                      "block w-full p-3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                      (errorMessage?.document_title
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                  />
                </div>
              </div>

              <div className="flex gap-4 flex-col sm:flex-row">
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                    Date of Document
                    {errorMessage?.date_of_document && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.date_of_document}
                      </span>
                    )}
                  </label>
                  <input
                    type="date"
                    value={dataDocument.date_of_document}
                    onChange={(e) => {
                      setDataDocument({
                        ...dataDocument,
                        date_of_document: e.target.value,
                      });
                    }}
                    className={
                      "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                      (errorMessage?.date_of_document
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                  />
                </div>
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Author
                    {errorMessage?.author && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.author}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    value={dataDocument.author}
                    onChange={(e) => {
                      setDataDocument({
                        ...dataDocument,
                        author: e.target.value,
                      });
                    }}
                    className={
                      "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                      (errorMessage?.author
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                  />
                </div>
              </div>
              <div className="flex gap-4 flex-col sm:flex-row">
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                    Practice Group
                    {errorMessage?.practice_group_id && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.practice_group_id}
                      </span>
                    )}
                  </label>
                  <Select
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.practice_group_id
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    isMulti
                    options={practiceGroup}
                    placeholder={`Input Practice Group`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={dataDocument.practice_group_id}
                    onChange={(values, actionMeta) => {
                      values.sort((a, b) =>
                        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                      );
                      setDataDocument({
                        ...dataDocument,
                        practice_group_id: values,
                      });
                    }}
                  />
                </div>

                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                    Industry Sector
                    {errorMessage?.industry_sector_id && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.industry_sector_id}
                      </span>
                    )}
                  </label>
                  <Select
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.industry_sector_id
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    isMulti
                    options={industrySector}
                    placeholder={`Input Industry Sector`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={dataDocument.industry_sector_id}
                    onChange={(values, actionMeta) => {
                      values.sort((a, b) =>
                        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                      );
                      setDataDocument({
                        ...dataDocument,
                        industry_sector_id: values,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="flex gap-4 flex-col sm:flex-row">
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                    Object
                    {errorMessage?.object_id && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.object_id}
                      </span>
                    )}
                  </label>
                  <Select
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.object_id
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    isMulti
                    options={rawObject}
                    placeholder={`Input Object`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={dataDocument.object_id}
                    onChange={(values, actionMeta) => {
                      values.sort((a, b) =>
                        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                      );
                      setDataDocument({
                        ...dataDocument,
                        object_id: values,
                      });
                    }}
                  />
                </div>

                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                    Action Type
                    {errorMessage?.action_type_id && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.action_type_id}
                      </span>
                    )}
                  </label>
                  <Select
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.action_type_id
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    isMulti
                    options={actionType}
                    placeholder={`Input Action Type`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={dataDocument.action_type_id}
                    onChange={(values, actionMeta) => {
                      values.sort((a, b) =>
                        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                      );
                      setDataDocument({
                        ...dataDocument,
                        action_type_id: values,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="mb-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                  Additional Information
                  {errorMessage?.document_briefs && (
                    <span className="ml-1 text-xs text-red-600">
                      *{errorMessage.document_briefs}
                    </span>
                  )}
                </label>
                <textarea
                  placeholder="(Note: Please insert/input the keywords you feel necessary to make searching easier)"
                  rows="4"
                  onChange={(e) => {
                    setDataDocument({
                      ...dataDocument,
                      document_briefs: e.target.value,
                    });
                  }}
                  value={dataDocument.document_briefs}
                  className={
                    "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                    (errorMessage?.document_briefs
                      ? "border-red-600"
                      : "border-gray-300")
                  }
                ></textarea>
              </div>
              <div className="flex gap-4 flex-col sm:flex-row">
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Document Status
                  </label>
                  <Select
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.document_type
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    options={documentStatuses}
                    placeholder={`Select Document Status`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={dataDocument.document_status}
                    onChange={(values, actionMeta) => {
                      setDataDocument({
                        ...dataDocument,
                        document_status: values,
                      });
                    }}
                  />
                </div>
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Language
                  </label>
                  <Select
                    isMulti={false}
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.language
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    options={languages}
                    placeholder={`Select Language`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={dataDocument.language}
                    onChange={(values, actionMeta) => {
                      console.log(values);
                      setDataDocument({
                        ...dataDocument,
                        language: values,
                      });
                    }}
                  />
                </div>

                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    <input
                      type="checkbox"
                      checked={linkToCase}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        setLinkToCase(e.target.checked);
                        if (!e.target.checked) {
                          setDataDocument({
                            lms_matter_id: null,
                            lms_case_number: null,
                            lms_client_id: null,
                            lms_client_name: null,
                            lms_case_name: null,
                            lms_originating_user_code: null,
                            lms_originating_user_name: null,
                          });
                        }
                      }}
                    />{" "}
                    Link to Case
                  </label>
                  {linkToCase && (
                    <CaseSelectAsync
                      defaultValue={document}
                      onCaseSelected={(e) => {
                        console.log(e);
                        const caseData = {
                          lms_matter_id: e.data.contract_id,
                          lms_case_number: e.data.contract_number,
                          lms_client_id: e.data.client.client_id,
                          lms_client_name: e.data.client.client_name,
                          lms_case_name: e.data.contract_name,
                          lms_originating_user_code:
                            e.data.originating_user_data.user_code,
                          lms_originating_user_name: `${e.data.originating_user_data.first_name} ${e.data.originating_user_data.middle_name} ${e.data.originating_user_data.last_name}`,
                        };
                        setDataDocument({
                          ...dataDocument,
                          ...caseData,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-4 flex-col sm:flex-row">
                {/* <div className="mb-2 w-full sm:w-1/2 ">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Tag
                    {errorMessage?.tags && (
                      <span className="ml-1 text-xs text-red-600">
                        *{errorMessage.tags}
                      </span>
                    )}
                  </label>
                  <CreatableSelect
                    className={
                      "mt-3 border rounded-md " +
                      (errorMessage?.tags
                        ? "border-red-600"
                        : "border-gray-300")
                    }
                    isClearable
                    isMulti
                    placeholder={`input Tags`}
                    components={{ DropdownIndicator: null }}
                    value={dataDocument.tags}
                    options={[]}
                    onChange={(values, actionMeta) => {
                      setDataDocument({ ...dataDocument, tags: values });
                      // console.log(values);
                    }}
                  />
                </div> */}

                <div className="mb-2 w-full sm:w-1/2 ">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Upload file{" "}
                    <label className="text-green-500">
                      *upload jika ingin diubah
                    </label>
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="file"
                    onChange={(e) => {
                      setDataDocument({
                        ...dataDocument,
                        document_file: e.target.files[0],
                      });
                      // console.log(e.target.files[0]);
                    }}
                  />
                  <div className="w-96"></div>
                </div>
              </div>
              <div className="mb-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                  Remark
                  {errorMessage?.remark && (
                    <span className="ml-1 text-xs text-red-600">
                      *{errorMessage.remark}
                    </span>
                  )}
                </label>
                <textarea
                  rows="4"
                  onChange={(e) => {
                    setDataDocument({
                      ...dataDocument,
                      remark: e.target.value,
                    });
                  }}
                  value={dataDocument.remark}
                  className={
                    "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                    (errorMessage?.remark
                      ? "border-red-600"
                      : "border-gray-300")
                  }
                ></textarea>
              </div>
            </div>
            {/*footer*/}
          </div>
          <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              onClick={() => {
                setShowModal(false);
                setErrorMessage({});
              }}
            >
              Close
            </button>
            <button
              type="button"
              disabled={saving}
              className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => {
                updateDocument();
              }}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
