import { useEffect, useState } from "react";
import axios from "axios";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FolderIcon,
  DocumentIcon,
  ArrowNarrowDownIcon,
} from "@heroicons/react/solid";

export default function Elo({
  onClick,
  tag_name,
  count = null,
  onDocSelected,
}) {
  const navigate = useNavigate();
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingChildId, setFetchingChildId] = useState(null);
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    fetchTree().then((e) => {
      console.log(e);
      setChildNodes(
        e.map((x) => ({
          id: x.id,
          name: x.doc_title,
          path: JSON.parse(x.browse_path ?? "[]").join("/"),
          type: x.objtype,
        }))
      );
    });
    return () => {};
  }, []);

  const fetchTree = () => {
    return new Promise(async (res, rej) => {
      setFetchingData(true);
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/elo/structure`,
          {
            params: {
              parent_id: -1,
            },
          }
        );
        if (resp.status == 200) {
          const { data } = resp;
          res(data);
        } else {
          rej({ error: "No data" });
        }
      } catch (error) {
        console.error(error);
        rej(error);
      } finally {
        setFetchingData(false);
      }
    });
  };
  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 14,
      fontWeightBold: 700,
    },
  });

  const getTreeItemsFromData = (treeItems) => {
    return treeItems.map((treeItemData) => {
      let children = undefined;
      let lastNode = [];
      if (treeItemData.children && treeItemData.children.length > 0) {
        if (!(treeItemData.children[0].type == "file")) {
          children = getTreeItemsFromData(treeItemData.children);
        } else {
          lastNode.push(treeItemData);
        }
      }

      if (lastNode.length !== 0) {
        return (
          <TreeItem
            key={treeItemData.name}
            nodeId={treeItemData.name}
            label={treeItemData.name.toUpperCase()}
            children={childNodes}
            onClick={(e) => {
              navigate("/elo/" + treeItemData.name);
            }}
          />
        );
      } else {
        return (
          <TreeItem
            key={treeItemData.name}
            nodeId={treeItemData.name}
            label={treeItemData.name.toUpperCase()}
            children={children}
          />
        );
      }
    });
  };

  return (
    <div className="mt-2">
      <ThemeProvider theme={theme}>
        <ExtTreeView
          onDocSelected={onDocSelected}
          initLoad={true}
          name={"ELO"}
          id={-1}
          items={childNodes}
        ></ExtTreeView>
      </ThemeProvider>
    </div>
  );
}

function ExtTreeView(props) {
  const [childNodes, setChildNodes] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingChildId, setFetchingChildId] = useState(null);
  useEffect(() => {
    if (props.initLoad) {
      handleChange({}, [-1]);
    }
    return () => {};
  }, [props.initLoad]);

  function fetchChildNodes(id) {
    return new Promise(async (resolve, rej) => {
      setFetchingData(true);
      setFetchingChildId(id);
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/elo/structure`,
          {
            params: {
              parent_id: id,
            },
          }
        );
        if (resp.status == 200) {
          const { data } = resp;
          resolve({
            children: data.map((x) => ({
              id: x.id,
              name: x.doc_title,
              label: x.doc_title,
              path: JSON.parse(x.browse_path ?? "[]").join("/"),
              type: x.objtype,
              ...x,
            })),
          });
        }
      } catch (error) {
        console.error(error);
        rej(error);
      } finally {
        setFetchingData(false);
        setFetchingChildId(null);
      }
    });
  }

  const handleChange = (event, nodes) => {
    if (props.type == "file") {
      console.log(`This is file`);
      props.onDocSelected(props);
    }
    console.log(event, nodes);
    setExpanded(nodes);
    const expandingNodes = nodes.filter((x) => !expanded.includes(x));
    if (expandingNodes[0]) {
      console.log(expandingNodes);
      const childId = expandingNodes[0];
      fetchChildNodes(childId).then((result) => {
        const childs = result.children.map((node) => (
          <ExtTreeView
            onDocSelected={props.onDocSelected}
            id={node.id}
            key={node.id}
            {...node}
            label={node.name}
            type={node.type}
          />
        ));
        console.log(childs);
        setChildNodes(childs);
      });
    }
  };
  let fileIcon = null;
  if (props.type == "file") {
    const filename = props.file_path.split(".");
    const ext = filename[1];
    let bgColor = "bg-slate-400";
    switch (ext.toLowerCase()) {
      case "pdf":
        bgColor = "bg-red-400";
        break;
      case "xls":
        bgColor = "bg-green-400";
        break;
      case "xlsx":
        bgColor = "bg-green-400";
        break;
      case "doc":
        bgColor = "bg-blue-400";
        break;
      case "docx":
        bgColor = "bg-blue-400";
        break;
    }
    fileIcon = (
      <span
        className={
          "rounded inline-block p-[1px] text-white text-xs mr-[1px] " + bgColor
        }
      >
        {ext.toLowerCase()}
      </span>
    );
  }
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      {/*The node below should act as the root node for now */}
      <TreeItem
        icon={props.objtype == "file" ? fileIcon : null}
        nodeId={props.id}
        label={
          <span>
            {props.name}{" "}
            {props.type == "file" && (
              <a
                className="inline-block hover:text-bold"
                onClick={(e) =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL +
                      "/elo/document-file/" +
                      props.file_path
                  )
                }
              >
                <ArrowNarrowDownIcon height={14} />
              </a>
            )}
          </span>
        }
      >
        {childNodes || [<div key="stub" />]}
      </TreeItem>
    </TreeView>
  );
}
