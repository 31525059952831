import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  DocumentDownloadIcon,
  DocumentTextIcon,
  PencilAltIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import {
  useLocation,
  useNavigate,
  useMatch,
  useParams,
  BrowserRouter,
} from "react-router-dom";
import moment from "moment";
import {
  downloadDocument,
  populateCategories,
  dateFormat,
  fileExtention,
} from "../libs/common";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import Loader from "./Loader";
import { isUserAllowedToEdit, isUserAllowedToDownload } from "../App";
import { useAlert } from "react-alert";
import FormEditDocument from "./FormEditDocument";
import { LanguageOutlined } from "@mui/icons-material";

const defaultValidationMessage = [
  {
    category_id: null,
    document_title: null,
    document_briefs: null,
    uploader: null,
    visible: null,
    uploader_phone: null,
    uploader_email: null,
    visibility: null,
    remark: null,
  },
];
const userLogged = JSON.parse(localStorage.getItem("HPRP_DOCUMENT_USER"));
const optionSelect = [
  // { value: "chocolate", label: "Chocolate" },
  // { value: "chocolate", label: "Chocolate" },
  // { value: "chocolate", label: "Chocolate" },
];
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ListDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [rawCategories, setRawCategories] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [rawObject, setRawObject] = useState([]);
  const [actionType, setActionType] = useState([]);
  const [practiceGroup, setPracticeGroup] = useState([]);
  const [industrySector, setIndustrySector] = useState([]);
  const location = useLocation();
  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dataDocument, setDataDocument] = useState();
  const [oldTag, setOldTag] = useState([]);
  const [rawTags, setRawTags] = useState([]);
  const [, update] = useState({});
  const [editTab, setEditTab] = useState("Edit");
  const [tab, setTab] = useState(["Edit", "Edit History", "Download History"]);
  const [validationMessage, setValidationMessage] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageList, setPageList] = useState([]);
  const [saving, setSaving] = useState(false);

  const Alert = useAlert();

  // const [downloadHistory, setDownload] = useState([]);

  const loadCategories = async () => {
    try {
      const getCategory = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document-category`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      const categories = populateCategories(getCategory.data.rows);
      setRawCategories(getCategory.data.rows);
      // console.log(categories);
      setCategory(categories);
    } catch (e) {
      console.log(e);
    }
  };

  const updateDocument = async () => {
    let errorMsg = {};
    if (!dataDocument.category_id)
      errorMsg.category_id = "category can't be null";
    if (!dataDocument.document_title)
      errorMsg.document_title = "title can't be null";
    if (!dataDocument.document_briefs)
      errorMsg.document_briefs = "brief can't be null";
    // if (!dataDocument.document_file)
    //   errorMsg.document_file = "file can't be null";
    if (!dataDocument.visibility)
      errorMsg.visibility = "visibility can't be null";
    if (!dataDocument.author) errorMsg.author = "author can't be null";
    if (dataDocument.object_id?.length < 1 || !dataDocument.object_id)
      errorMsg.object_id = "Object can't be null";
    if (
      dataDocument.practice_group_id?.length < 1 ||
      !dataDocument.practice_group_id
    )
      errorMsg.practice_group_id = "Practice Group can't be null";
    if (dataDocument.action_type_id?.length < 1 || !dataDocument.action_type_id)
      errorMsg.action_type_id = "Type of agreement can't be null";
    if (
      dataDocument.industry_sector_id?.length < 1 ||
      !dataDocument.industry_sector_id
    )
      errorMsg.industry_sector_id = "industry sector can't be null";
    if (!dataDocument.remark) errorMsg.remark = "remark harus diisi";
    if (Object.keys(errorMsg).length > 0) {
      setErrorMessage(errorMsg);
      console.log(errorMsg);
      console.log(dataDocument);
      setSaving(false);

      return;
    }
    setSaving(true);
    let form = new FormData();
    form.append("category_id", dataDocument.category_id);
    form.append("document_title", dataDocument.document_title);
    form.append("document_briefs", dataDocument.document_briefs);
    form.append("uploader", userLogged?.user_code);
    form.append("visible", dataDocument.visible);
    form.append("uploader_phone", userLogged?.call_phone_number);
    form.append("uploader_email", userLogged?.email);
    form.append("visibility", dataDocument.visibility);
    form.append("remark", dataDocument.remark);
    form.append("date_of_document", dataDocument.date_of_document);
    form.append("author", dataDocument.author);
    dataDocument.object_id.forEach((x) => form.append("object_id[]", x.value));
    dataDocument.practice_group_id.forEach((x) =>
      form.append("practice_group_id[]", x.value)
    );
    dataDocument.industry_sector_id.forEach((x) =>
      form.append("industry_sector_id[]", x.value)
    );
    dataDocument.action_type_id.forEach((x) =>
      form.append("action_type_id[]", x.value)
    );
    if (dataDocument.document_file) {
      form.append("document_file", dataDocument.document_file);
    }
    for (let user of dataDocument.user_codes) {
      form.append("user_codes[]", user.value);
    }
    try {
      const documentUpdate = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/document/${dataDocument.id}`,
        form,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
            "content-type": "multipart/form-data",
          },
        }
      );
      let tag_names = [];
      dataDocument?.tags?.map((x) => {
        tag_names.push(x.value);
      });

      const updateTag = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/tags/put-tag/`,
        { tag_names, document_id: dataDocument.id },
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );

      oldTag?.map(async (x) => {
        const duplicate = dataDocument.tags.find((e) => e.value == x.tag);
        if (!duplicate) {
          // console.log(duplicate);
          const deleteTag = await axios.delete(
            `${process.env.REACT_APP_SERVER_URL}/tags/${dataDocument.id}/${x.tag}`,
            {
              headers: {
                token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
              },
            }
          );
        }
      });
      Alert.show("Data berhasil disimpan!");
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
      setErrorMessage({});

      loadDocuments();

      // console.log(documentUpdate.data);
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  const loadDocuments = async () => {
    const extraParams = {};
    if (query.has("category_id")) {
      extraParams.category_id = query.get("category_id");
    }
    if (query.has("tag")) {
      extraParams.tags = [query.get("tag")];
    }
    try {
      setLoadingDocuments(true);
      const getDocuments = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/?limit=${limit}&offset=${
          (page - 1) * limit
        }`,
        {
          params: {
            ...location.state,
            ...extraParams,
          },
        }
      );
      // console.log(getDocuments.data.rows);
      setDocuments(getDocuments.data.rows);
      setOldTag(getDocuments?.data?.rows[0]?.tags);
      let listpage = [];
      for (let i = 0; i < Math.ceil(getDocuments.data.count / limit); i++) {
        listpage.push(i + 1);
      }
      // console.log(listpage);
      setPageList(listpage);

      // console.log(getDocuments.data[0].tags);
      // console.log(getDocuments.data);
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setLoadingDocuments(false);
      }, 1000);
    }
  };

  const loadCategory = async () => {
    try {
      const getCategory = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document-category`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setRawCategories(getCategory.data.rows);
    } catch (e) {
      console.log(e);
    }
  };

  const loadOneCategory = async () => {
    try {
      const getCategory = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/document-category?category_id=${query.get("category_id")}`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setSelectedCategory(getCategory.data.rows[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const loadTags = async () => {
    try {
      const getTags = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/tags/`
      );
      setRawTags(getTags.data);
      // console.log(getTags.data);
    } catch (e) {
      console.log(e);
    }
  };

  // load data object
  const loadObject = async () => {
    try {
      // get data from backend
      const getObject = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/object`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      let values = [];
      getObject?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setRawObject(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Action Type
  const loadActionType = async () => {
    try {
      // get data from backend
      const getActionType = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/action-type`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      let values = [];
      getActionType?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setActionType(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Practice Group
  const loadPracticeGroup = async () => {
    try {
      // get data from backend
      const getPracticeGroup = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/practice-group`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      let values = [];
      getPracticeGroup?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setPracticeGroup(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Industry Sector
  const loadIndustrySector = async () => {
    try {
      // get data from backend
      const getIndustrySector = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/industry-sector`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      let values = [];
      getIndustrySector?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setIndustrySector(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // console.log(params, query);
    loadObject();
    loadActionType();
    loadPracticeGroup();
    loadIndustrySector();

    loadCategories();
    loadDocuments();
    loadOneCategory();
    return () => {};
  }, [params, query, page]);

  useEffect(() => {
    const tabs = [];
    if (isUserAllowedToEdit()) {
      tabs.push("Edit");
    }

    tabs.push("Edit History");
    tabs.push("Download History");
    setTab(tabs);
    return () => {};
  }, []);
  useEffect(() => {
    console.log(location);
    return () => {};
  }, [location]);
  return (
    <>
      <div className="flex flex-col-reverse sm:flex-row">
        <div className="basis-1/4 px-2 mb-2">
          <SideMenu categories={category} highlightCategory={params.category} />
        </div>
        <div className="basis-3/4">
          <div className="text-lg sm:text-2xl font-bold px-4 flex items-start  mt-4">
            {/* <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 "
            >
              Default
            </button> */}
            <ArrowCircleLeftIcon
              className="w-8 text-green-500 cursor-pointer mr-1"
              onClick={() => {
                navigate(-1);
              }}
            />
            {selectedCategory && (
              <React.Fragment>
                List Dokumen {selectedCategory?.category_name}
              </React.Fragment>
            )}
            {params.category && (query.get("q") || query.get("tag")) && (
              <React.Fragment>&nbsp;</React.Fragment>
            )}
            {query.get("q") && (
              <React.Fragment>Search : {query.get("q")}</React.Fragment>
            )}
            {query.get("tag") && (
              <React.Fragment>Tag : {query.get("tag")}</React.Fragment>
            )}
          </div>

          {!loadingDocuments &&
            documents.length > 0 &&
            documents.map((row) => {
              let lang = null;
              let lang_style = "text-white";
              let ext = fileExtention(row.file);
              let ext_style = "";
              switch (row.language) {
                case "id":
                  lang = "ID";
                  lang_style = "text-green-600";
                  break;
                case "en":
                  lang = "ENG";
                  lang_style = "text-sky-600";
                  break;
                case "multi":
                  lang = "Bilingual";
                  lang_style = "text-purple-800";
                  break;
                case "other":
                    lang = "Other";
                    lang_style = "text-slate-800";
                    break;
                default:
              }
              switch (ext) {
                case "PDF":
                  ext_style = "text-red-600";
                  break;
                case "MS WORD":
                  ext_style = "text-blue-600";
                  break;
                case "PPT":
                  ext_style = "text-orange-500";
                  break;
                default:
              }
              return (
                <div
                  key={row.id}
                  className=" m-4 border rounded-lg border-gray-400 "
                >
                  <div className="flex justify-between px-4 pt-4">
                    <div className="flex">
                      <div className="h-6">
                        <DocumentTextIcon className="h-6" />
                      </div>
                      <div
                        className="ml-2 text-base sm:text-xl font-semibold text-limit-1 cursor-pointer"
                        onClick={() => {
                          // dateFormat(row.created_at);
                          navigate(`/document/${row.id}`);
                          // downloadDocument(row.id);
                        }}
                      >
                        {row.document_title}
                      </div>
                    </div>
                  </div>
                  <div className="text-limit-3 sm:text-base text-sm px-4">
                    {row.document_briefs}
                  </div>
                  <div className="mt-2 text-black px-4 py-3 bg-gradient-to-r from-gray-500 to-white rounded-b-md flex justify-start sm:justify-end gap-4">
                    {row.language && row.language != "" && (
                      <div className={`text-sm sm:text-base ${lang_style} flex items-center`}>
                        <LanguageOutlined className="w-6 mr-1" /> {lang}
                      </div>
                    )}
                    <div className={`text-sm sm:text-base ${ext_style} flex items-center`}>
                      <DocumentTextIcon className="w-6 mr-1" /> {ext}
                    </div>
                    <div className="text-sm sm:text-base flex items-center">
                      <UserCircleIcon className="w-6 mr-1" /> {row.uploader}
                    </div>
                    <div className="text-sm sm:text-base flex items-center">
                      <ClockIcon className="w-6 mr-1" />{" "}
                      {dateFormat(row.date_of_document)}
                    </div>
                  </div>
                </div>
              );
            })}
          {!loadingDocuments && pageList.length > 1 && (
            <nav
              aria-label="Page navigation"
              className="p-4 flex justify-center mt-2"
            >
              <ul className="inline-flex items-center -space-x-px cursor-pointer">
                <li>
                  <a
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                      }
                    }}
                    className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <ChevronLeftIcon className="w-5 h-5" />
                  </a>
                </li>
                {pageList.map((x) => (
                  <li key={x}>
                    <a
                      onClick={() => {
                        setPage(x);
                      }}
                      className={
                        "py-2 px-3 leading-tight   border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white " +
                        (page == x
                          ? "bg-purple-500 text-white"
                          : "text-gray-500 border bg-white")
                      }
                    >
                      {x}
                    </a>
                  </li>
                ))}

                <li>
                  <a
                    onClick={() => {
                      if (page < pageList.length) {
                        setPage(page + 1);
                      }
                    }}
                    className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <ChevronRightIcon className="w-5 h-5" />
                  </a>
                </li>
              </ul>
            </nav>
          )}
          {!loadingDocuments && documents.length <= 0 && (
            <>
              <img
                src="/empty-recolor.jpg"
                className="w-full sm:w-2/3 mx-auto "
              />
            </>
          )}
          {loadingDocuments && (
            <div className="flex flex-row items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>

      <FormEditDocument
        document={dataDocument}
        setShowModal={setShowModal}
        showModal={showModal}
        onDocumentUpdated={loadDocuments}
      />
    </>
  );
};

export default ListDocuments;
