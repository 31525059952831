import React, { useEffect, useState } from "react";
import {
  ArrowSmRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilAltIcon,
  PlusIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { data } from "autoprefixer";

const initIndustrySector = {
  name: "",
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const IndustrySector = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const query = useQuery();
  const [type, setType] = useState("add");
  const [showModal, setShowModal] = useState(false);
  const [IndustrySector, setIndustrySector] = useState([]);
  const [, update] = useState({});
  const [dataIndustrySector, setDataIndustrySector] =
    useState(initIndustrySector);
  const [savingIndustrySector, setSavingIndustrySector] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageList, setPageList] = useState([]);

  const saveIndustrySector = async () => {
    let errorMsg = {};
    if (!dataIndustrySector.name) errorMsg.name = "nama harus diisi";
    if (Object.keys(errorMsg).length > 0) {
      setErrorMessage(errorMsg);
      return;
    }

    // searchQuery;

    setSavingIndustrySector(true);
    try {
      let IndustrySector;
      IndustrySector = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/document/Industry-sector`,
        dataIndustrySector,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );

      // setDocuments(getDocuments.data);
      setShowModal(false);
      console.log(IndustrySector.data);
      loadIndustrySector();
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setSavingIndustrySector(false);
      }, 1000);
    }
    console.log(dataIndustrySector);
  };
  const loadIndustrySector = async () => {
    try {
      setSavingIndustrySector(true);
      const getIndustrySector = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/Industry-sector?search=${
          query.get("q") ? query.get("q") : ""
        }&limit=${limit}&offset=${(page - 1) * limit}`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setIndustrySector(getIndustrySector.data.rows);
      let listpage = [];
      for (
        let i = 0;
        i < Math.ceil(getIndustrySector.data.count / limit);
        i++
      ) {
        listpage.push(i + 1);
      }
      setPageList(listpage);
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setSavingIndustrySector(false);
      }, 1000);
    }
  };
  useEffect(() => {
    loadIndustrySector();
    return () => {};
  }, [query, page, limit]);

  // useEffect(() => {
  //   setCategory(category);
  //   console.log(category);
  // }, [category]);

  return (
    <div>
      <button
        className="z-30 fixed bottom-6 right-6 bg-main py-4 rounded-xl flex items-center px-4 font-medium text-xs  leading-tight  shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
        onClick={(e) => {
          setType("add");
          setDataIndustrySector(initIndustrySector);
          setShowModal(true);
        }}
      >
        <PlusIcon className="w-6 text-white" />
        <div className="text-white font-semibold text-base ml-2">
          Add Industry Sector
        </div>
      </button>

      <div className="text-2xl font-bold mb-4">
        List Industry Sector
        {query.get("q") && (
          <React.Fragment> Search : {query.get("q")}</React.Fragment>
        )}
      </div>
      <div className="grid grid-flow-col gap-4">
        <div className="row-span-2 col-span-2 ">
          <div className="flex flex-row justify-between relative mb-6 ">
            <div className="">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </div>
              <input
                type="search"
                className="block pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                placeholder="Search Name..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setSearchQuery("");
                    navigate(location.pathname + "?q=" + searchQuery);
                  }
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="mr-2">Tampilkan</label>

              <select
                onChange={(e) => {
                  setLimit(e.target.value);
                }}
                value={limit}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full py-2.5 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {IndustrySector.map((row, index) => (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {row.name}
                      </td>
                      <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        <button
                          type="button"
                          className="flex items-center focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 font-medium rounded-lg text-sm px-5 py-2"
                          onClick={(e) => {
                            setShowModal(true);
                            setType("edit");
                            setDataIndustrySector({
                              id: row.id,
                              name: row.name,
                            });
                          }}
                        >
                          <PencilAltIcon className="w-5 mr-1" />
                          Edit
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {pageList.length > 1 && (
            <nav
              aria-label="Page navigation"
              className="p-4 flex justify-center mt-2"
            >
              <ul className="inline-flex items-center -space-x-px cursor-pointer">
                <li>
                  <a
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                      }
                    }}
                    className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <ChevronLeftIcon className="w-5 h-5" />
                  </a>
                </li>
                {pageList.map((x) => (
                  <li>
                    <a
                      onClick={() => {
                        setPage(x);
                      }}
                      className={
                        "py-2 px-3 leading-tight   border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white " +
                        (page == x
                          ? "bg-purple-500 text-white"
                          : "text-gray-500 border bg-white")
                      }
                    >
                      {x}
                    </a>
                  </li>
                ))}

                <li>
                  <a
                    onClick={() => {
                      if (page < pageList.length) {
                        setPage(page + 1);
                      }
                    }}
                    className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <ChevronRightIcon className="w-5 h-5" />
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
      {showModal ? (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center flex bg-black/50">
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {type == "add"
                    ? "Add Industry Sector"
                    : "Edit Industry Sector"}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="flex gap-8">
                  <div className="mb-4 w-full">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Name
                      {errorMessage?.user_code && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.user_code}
                        </span>
                      )}
                    </label>

                    <input
                      type="text"
                      onChange={(e) => {
                        setDataIndustrySector({
                          ...dataIndustrySector,
                          name: e.target.value,
                        });
                      }}
                      required
                      value={dataIndustrySector.name}
                      className={
                        "block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        (errorMessage?.user_code
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => {
                    setShowModal(false);
                    setErrorMessage({});
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => {
                    saveIndustrySector();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

//
export default IndustrySector;
