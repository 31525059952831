import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tag from "./Tag";
import axios from "axios";
import Elo from "./Elo";

const SideMenu = ({ categories, highlightCategory }) => {
	const [loadingTags, setLoadingTags] = useState(false);
	const [tags, setTags] = useState([]);
	const navigate = useNavigate();
	const loadTagsAndCount = async () => {
		try {
			setLoadingTags(true);
			const resp = await axios.get(process.env.REACT_APP_SERVER_URL + "/tags/");
			setTags(resp.data);
		} catch (error) {
			console.error(error);
		} finally {
			setTimeout(() => {
				setLoadingTags(false);
			}, 1000);
		}
	};
	useEffect(() => {
		loadTagsAndCount();
		return () => { };
	}, []);
	return (
		<>
			<div className=" row-span-3 col-span-2">
				<div className="rounded-lg border-solid border-gray-200 border-2 p-6 grid grid-cols-1">
					<div className="font-bold text-lg mb-2">Document Type</div>
					<div className="border-b-2 border-gray-400" />
					{(categories ?? []).map((x) => (
						<RecursiveMenu data={x} highlightOn={highlightCategory} />
					))}
				</div>
				{/* <div className="rounded-lg border-solid border-gray-200 border-2 p-6 grid grid-cols-1 mt-2">
					<div className="font-bold text-lg mb-2">ELO</div>
					<div className="border-b-2 border-gray-400 " />
					<Elo />
				</div> */}
				<div className="rounded-lg border-solid border-gray-200 border-2 p-6 grid grid-cols-1 mt-2">
					<div className="font-bold text-lg mb-2">Tag</div>
					<div className="border-b-2 border-gray-400 " />
					<div className="mt-2">
						{tags.map((x) => (
							<Tag
								onClick={(e) => {
									navigate("/list?tag=" + x.tag);
								}}
								tag_name={x.tag}
								count={x.tag_count}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

function RecursiveMenu({ data, level = 1, highlightOn = null }) {
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<a
				href="#"
				className={"p-1 hover:font-bold font-medium my-1" + (highlightOn == data.id ? " font-bold" : "")}
				onClick={() => {
					navigate(`/list?category_id=${data.id}`);
				}}
				style={{ marginLeft: (level - 1) * 15 + "px" }}
			>
				{data.category_name}
			</a>
			{(data.sub_category ?? []).map((x) => (
				<RecursiveMenu data={x} level={level + 1} highlightOn={highlightOn} />
			))}
		</React.Fragment>
	);
}

export default SideMenu;
