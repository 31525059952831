import axios from "axios";
import React, { Component } from "react";
import AsyncSelect from "react-select/async";

export default function CaseSelectAsync({
  onCaseSelected,
  value,
  defaultValue,
}) {
  const loadOptions = async (inputValue, callback) => {
    console.log(inputValue);
    try {
      const resp = await axios.get(
        `https://hprp-cloud.com/api/document/documents`,
        {
          params: {
            search: inputValue,
          },
        }
      );

      if (resp.status == 200) {
        const options = resp.data.map((x) => {
          return {
            value: x.contract_id,
            label:
              (x.contract_number ? `[${x.contract_number}] ` : "") +
              x.contract_name,
            data: x,
          };
        });

        callback(options);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <AsyncSelect
      defaultOptions={
        defaultValue
          ? [
              {
                value: defaultValue.lms_matter_id,
                label:
                  (defaultValue.lms_case_number
                    ? `[${defaultValue.lms_case_number}] `
                    : "") + defaultValue.lms_case_name,
              },
            ]
          : []
      }
      defaultValue={
        defaultValue
          ? {
              value: defaultValue.lms_matter_id,
              label:
                (defaultValue.lms_case_number
                  ? `[${defaultValue.lms_case_number}] `
                  : "") + defaultValue.lms_case_name,
            }
          : null
      }
      placeholder="Select linked case"
      cacheOptions
      loadOptions={loadOptions}
      onChange={onCaseSelected}
    />
  );
}
