import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toText } from "../libs/common";
import axios from "axios";
const initialData = {
  username: "",
  password: "",
};
const Login = () => {
  // console.log(process.env);
  const navigate = useNavigate();
  const [user, setUser] = useState(initialData);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [autoLoggingIn, setAutoLoggingIn] = useState(false);
  const [flag, setFlag] = useState(true);
  const cookie = checkCookie() ? getCookie('_dentons') : null;  
  
  const autologin = async () => {    
    try {
      setErrorMsg(null)
      setAutoLoggingIn(true);
      const userLogin = getUser();
      const tryLogin = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/login/`,
        userLogin
      );
      localStorage.setItem("HPRP_DOCUMENT_TOKEN", tryLogin.data.token);
      localStorage.setItem("HPRP_DOCUMENT_USER", JSON.stringify(tryLogin.data));
      const users = JSON.parse(localStorage.getItem("HPRP_DOCUMENT_USER"));

      // Set axios interceptor
      // Automatically append token to headers
      axios.interceptors.request.use((config) => {
        config.headers.token = tryLogin.data.token;
        return config;
      });

      // setDocuments(getDocuments.data);
      setTimeout(() => {
        // navigate("/", { replace: true });
        window.location.replace("https://doc.hprp-cloud.com");
      }, 1000);
    } catch (e) {
      if (e?.response) {
        setErrorMsg(e.response.data.message);
      } else {
        setErrorMsg("Please login dashboard first")
      }
    } finally {
      setTimeout(() => {
        setAutoLoggingIn(false);
      }, 1000);
    }
  }

  const login = async () => {
    // console.log(localStorage.getItem("HPRP_DOCUMENT_TOKEN"));
    try {
      setErrorMsg(null)
      setLoggingIn(true);
      // console.log(`URL : ${process.env.REACT_APP_SERVER_URL}/auth/login/`);
      // console.log('process')
      const tryLogin = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/login/`,
        user
      )
      localStorage.setItem("HPRP_DOCUMENT_TOKEN", tryLogin.data.token);
      localStorage.setItem("HPRP_DOCUMENT_USER", JSON.stringify(tryLogin.data));
      const users = JSON.parse(localStorage.getItem("HPRP_DOCUMENT_USER"));
      console.log(users)

      // Set axios interceptor
      // Automatically append token to headers
      axios.interceptors.request.use((config) => {
        config.headers.token = tryLogin.data.token;
        return config;
      });

      // setDocuments(getDocuments.data);
      setTimeout(() => {
        // navigate("/", { replace: true });
        window.location.replace("https://doc.hprp-cloud.com");
      }, 1000);
    } catch (e) {
      console.log(e);
      if (e.response) {
        setErrorMsg(e.response.data.message);
      }
    } finally {
      setTimeout(() => {
        setLoggingIn(false);
      }, 1000);
    }
  };

  // login by cookie
  // if (cookie && flag) {
  //   setFlag(false);    
  //   autologin();
  // }

  function getUser() {
    const dec = cookie.match(/.{1,2}/g).map(function(v){
      return String.fromCharCode(parseInt(v, 16));
    }).join('');
    const obj = JSON.parse(dec);

    return {
      username: obj.authorityType,
      password: toText(obj.authorityToken)
    }
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let dentons = getCookie("_dentons");
    return dentons ? true : false;
  }

  return (
    <section className="h-screen -mt-4">
      <div className="px-6 h-full text-gray-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="w-full"
              alt="Sample image"
            />
          </div>
          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <form>
              <div className="mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  placeholder="Username"
                  value={user.username}
                  onChange={(e) => {
                    setUser({ ...user, username: e.target.value });
                  }}
                />
              </div>

              <div className="mb-6">
                <input
                  type="password"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  placeholder="Password"
                  value={user.password}
                  onChange={(e) => {
                    setUser({ ...user, password: e.target.value });
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (!loggingIn) login();
                    }
                  }}
                />
              </div>

              {/* <div className="flex justify-between items-center mb-6">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    id="exampleCheck2"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    for="exampleCheck2"
                  >
                    Remember me
                  </label>
                </div>
                <a href="#!" className="text-gray-800">
                  Forgot password?
                </a>
              </div> */}

              <div className="text-center lg:text-left">
                <button
                  type="button"
                  className={
                    "inline-block px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  }
                  style={{
                    backgroundColor: loggingIn ? "#b493c7" : "#6f3091",
                  }}
                  disabled={(loggingIn !== autoLoggingIn)}
                  onClick={() => {
                    // navigate("/documents");
                    if (!loggingIn) login();
                  }}
                >
                  { (loggingIn) ? "Please wait..." : "Login" }
                </button>
                
                {" "}&nbsp;

                <button
                  type="button"
                  className={
                    "inline-block px-7 py-3 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  }
                  style={{
                    background: "#fff",
                    borderColor: "#702083",
                    color: "#702083",
                  }}
                  disabled={(loggingIn !== autoLoggingIn)}
                  onClick={() => {
                    setFlag(false);    
                    autologin();
                  }}
                >
                  { (autoLoggingIn) ? "Please wait..." : "Continue with Dashboard Account" }
                </button>
                <br/><br/>
                {errorMsg && (
                  <span className="text-danger" style={{ marginLeft: 20 }}>
                    Error: Username/password must not be null
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
