import axios from "axios";

export const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "id",
    label: "Indonesia",
  },
  {
    value: "multi",
    label: "Bilingual",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const downloadDocument = async (
  id,
  revision_id = null,
  documentData
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/document/download/${id}`,
      { responseType: "blob", params: { revision_id } }
    );
    console.log(documentData);
    const href = URL.createObjectURL(response.data);

    const filePaths = documentData.file.split(".");
    const extension = filePaths[filePaths.length - 1];

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      documentData.document_title + "." + extension
    ); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};

export const previewDocument = async (
  id,
  revision_id = null,
  documentData
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/document/download/${id}`,
      { responseType: "blob", params: { revision_id } }
    );
    console.log(documentData);

    const newBlob = new Blob([response.data], {type: "application/pdf"});
    const newWindow = window.open('', documentData.document_title, "width=800,height=1200");
    console.log(newWindow);
    if (newWindow) {
        setTimeout( () => {
            const dataUrl = window.URL.createObjectURL(newBlob);
            const title = newWindow.document.createElement('title');
            const iframe = newWindow.document.createElement('iframe');

            title.appendChild(document.createTextNode(documentData.document_title));
            newWindow.document.head.appendChild(title);

            iframe.setAttribute('src', dataUrl);
            iframe.setAttribute('width', "100%");
            iframe.setAttribute('height', "100%");

            newWindow.document.body.appendChild(iframe);

            setTimeout( () => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(dataUrl);
            }, 100);
        }, 100);
    } else {
        alert("To display document, please disable any pop-blockers for this page and try again.");
    }
    
  } catch (error) {
    console.error(error);
  }
}; 

export const documentStatuses = [
  { value: "draft", label: "Draft" },
  { value: "executed", label: "Executed" },
];
export function populateCategories(data) {
  const finalCategories = [];
  const categoryWithoutParent = data.filter((x) => x.parent_id == null);
  for (let cat of categoryWithoutParent) {
    recursivePopulateCategories(cat, data);
    finalCategories.push(cat);
    cat.hidesub = true;
  }
  return finalCategories;
}
export function recursivePopulateCategories(data, allData) {
  data.sub_category = [];
  const children = allData.filter((x) => x.parent_id == data.id);
  for (let child of children) {
    child.hidesub = true;
    recursivePopulateCategories(child, allData);
    data.sub_category.push(child);
  }
}

export function dateFormat(date) {
  const dates = new Date(date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return dates.toLocaleDateString(["ban", "id"], options);
}

export function fileExtention(file) {
  let ext = "";

  if (file) {
    const findExt = file.split(".");
    ext = findExt[findExt.length - 1];
    ext = ext.toUpperCase();

    if (ext === "DOC" || ext === "DOCX") {
      ext = "MS WORD"
    }

    if (ext === "PPT" || ext === "PPTX") {
      ext = "PPT"
    }
  }  

  return ext;
}

export function toText(str) {
  const tx = str;
  const strSplit = tx.match(/.{1,3}/g).map(Number);
  const strFinal = strSplit.reduce(
    (prev, c) => prev + String.fromCharCode(c),
    ""
  );
  return strFinal;
}
