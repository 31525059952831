import React, { useEffect, useState } from "react";
import Elo from "../component/Elo";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function EloDetail() {
	const [selectedDoc, setSelectedDoc] = useState(null)
	useEffect(() => {
		console.log(selectedDoc)
		return () => {

		}
	}, [selectedDoc])
	const getDocUri = () => {
		if (!selectedDoc) return null
		const d = `${process.env.REACT_APP_SERVER_URL}/elo/document-file/${selectedDoc.file_path.split('\\').join('/')}`
		console.log(d)
		return d
	}
	return (
		<div>
			<div className="flex flex-col md:flex-row">
				{/* call sidebar */}
				<div className="basis-5/12 px-2">
					<div className="rounded-lg border-solid border-gray-200 border-2 p-6 grid grid-cols-1">
						<div className="font-bold text-lg mb-2">ELO</div>
						<div className="border-b-2 border-gray-400 " />
						<div className="overflow-y-auto max-h-[800px]">
							<Elo onDocSelected={setSelectedDoc} />
						</div>
					</div>
				</div>
				{/* end sidebar */}
				<div className="basis-7/12 px-2">
					<div className="row-span-1 col-span-8 border-solid border-2 pt-2 pl-4 pr-4 pb-2 border-gray-100 rounded-lg">
						<div className=" grid sm:grid-cols-1 gap-6">
							<div class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
								{selectedDoc && <DocViewer style={{ minHeight: 800 }} documents={[{ uri: `${process.env.REACT_APP_SERVER_URL}/elo/document-file/${selectedDoc.file_path.split('\\').join('/')}` }]} pluginRenderers={DocViewerRenderers} />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
