import { SearchIcon, XIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { languages } from "../libs/common";
// Profile Dropdown
const Header = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const profileRef = useRef();

  const navigation = [
    // { title: "Dashboard", path: "javascript:void(0)" },
    // { title: "Settings", path: "javascript:void(0)" },
    {
      title: "Log out",
      onClick: (e) => {
        localStorage.clear();
        navigate("/login");
      },
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("HPRP_DOCUMENT_TOKEN") === null) {
      navigate("/login");
    }
    
    const handleDropDown = (e) => {
      if (!profileRef.current || !profileRef.current.contains(e.target))
        setState(false);
    };
    document.addEventListener("click", handleDropDown);
  }, []);

  return (
    <div className={`relative ${props.class}`}>
      <div className="flex items-center space-x-4">
        <button
          ref={profileRef}
          className="w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 ring-2 lg:focus:ring-indigo-600"
          onClick={() => setState(!state)}
        >
          <img src="/profile-pic.png" className="w-full h-full rounded-full" />
        </button>
        {/* <div className="lg:hidden">
          <span className="block">Micheal John</span>
          <span className="block text-sm text-gray-500">john@gmail.com</span>
        </div> */}
      </div>
      <ul
        className={`bg-white top-12 right-0 mt-5 space-y-5 lg:absolute lg:border lg:rounded-md lg:text-sm lg:w-52 lg:shadow-md lg:space-y-0 lg:mt-0 ${
          state ? "" : "lg:hidden"
        }`}
      >
        {navigation.map((item, idx) => (
          <li>
            <a
              key={idx}
              className="block text-gray-600 lg:hover:bg-gray-50 lg:p-2.5 cursor-pointer"
              href={item.path}
              onClick={item.onClick}
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function HeaderComponent () {
  const [searchQuery, setSearchQuery] = useState("");
  const [menuState, setMenuState] = useState(false);

  const [rawObject, setRawObject] = useState([]);
  const [actionType, setActionType] = useState([]);
  const [practiceGroup, setPracticeGroup] = useState([]);
  const [industrySector, setIndustrySector] = useState([]);
  const [searchDocument, setSearchDocument] = useState({});
  const [rawCategories, setRawCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  // Replace javascript:void(0) path with your path
  const navigation = [
    { title: "Home", path: "/" },
    { title: "ELO", path: "/elo" },
    { title: "Document Type", path: "/category" },
    { title: "Permission", path: "/permission" },
    { title: "Object", path: "/document/object" },
    { title: "Action Type", path: "/document/action-type" },
    { title: "Practice Group", path: "/document/practice-group" },
    { title: "Industry Sector", path: "/document/industry-sector" },
  ];

  const search = () => {
    console.log(searchDocument);
    navigate("/list", {
      state: {
        ...searchDocument,
        industry_sector_id: (searchDocument.industry_sector_id ?? []).map(
          (x) => x.value
        ),
        practice_group_id: (searchDocument.practice_group_id ?? []).map(
          (x) => x.value
        ),
        tags: (searchDocument.tags ?? []).map((x) => x.value),
        action_type_id: (searchDocument.action_type_id ?? []).map(
          (x) => x.value
        ),
        object_id: (searchDocument.object_id ?? []).map((x) => x.value),
        category_id: searchDocument.category_id?.value ?? null,
        language:searchDocument.language?.value ?? null
      },
    });
    setShowModal(false);
    setSearchDocument([]);
  };
  // load data object
  const loadObject = async () => {
    try {
      // get data from backend
      const getObject = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/object`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getObject?.data?.rows?.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getObject?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setRawObject(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Action Type
  const loadActionType = async () => {
    try {
      // get data from backend
      const getActionType = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/action-type`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getActionType?.data?.rows?.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getActionType?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setActionType(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Practice Group
  const loadPracticeGroup = async () => {
    try {
      // get data from backend
      const getPracticeGroup = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/practice-group`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getPracticeGroup?.data?.rows?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getPracticeGroup?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setPracticeGroup(values); // set data
    } catch (e) {
      console.log(e);
    }
  };

  // load data Industry Sector
  const loadIndustrySector = async () => {
    try {
      // get data from backend
      const getIndustrySector = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document/industry-sector`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );
      try {
        getIndustrySector?.data?.rows?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getIndustrySector?.data?.rows?.map((x) => {
        values.push({ label: x.name, value: x.id });
      });
      setIndustrySector(values); // set data
    } catch (e) {
      console.log(e);
    }
  };
  // load data category
  const loadCategories = async () => {
    try {
      // get data from backend
      const getCategory = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/document-category`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"), //send token from local storage
          },
        }
      );

      // const categories = populateCategories(getCategory.data.rows); //populate categories child and parent
      // setRawCategories(getCategory.data.rows); //set data from backend to state populate
      try {
        getCategory?.data?.rows?.sort((a, b) =>
          a.category_name.localeCompare(b.category_name)
        );
      } catch (error) {
        console.log(error);
      }
      let values = [];
      getCategory?.data?.rows?.map((x) => {
        values.push({ label: x.category_name, value: x.id });
      });
      setRawCategories(values);
      // setCategory(categories); // set data category
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (!showModal) return;
    loadObject();
    loadActionType();
    loadPracticeGroup();
    loadIndustrySector();
    loadCategories();
    return () => {};
  }, [showModal]);

  useEffect(() => {
    setSearchQuery("");
    return () => {};
  }, [location]);
  return (
    <>
      {location?.pathname != "/login" && (
        <nav className="bg-white border-b">
          <div className="flex items-center space-x-8 py-3 px-4 container mx-auto">
            {location?.pathname != "/embed" &&
              location?.pathname != "/embed_list" && (
                <div className="flex-none lg:flex-initial">
                  <a href="javascript:void(0)">
                    <img
                      onClick={() => navigate("/")}
                      src="https://dentons.hprplawyers.com/-/media/images/website/logos/dentons_hprp_logo.png?h=40&iar=0&w=242&sc_lang=en&hash=3E51183E26D9489C836DB25FD2A563C5"
                      width={200}
                      height={60}
                    />
                  </a>
                </div>
              )}
            <div className="flex-1 flex items-center justify-between">
              <div
                className={`bg-white absolute z-20 w-full top-16 left-0 p-4 border-b lg:static lg:block lg:border-none ${
                  menuState ? "" : "hidden"
                }`}
              >
                <ul className="sm:mt-12 space-y-5 lg:flex lg:space-x-6 lg:space-y-0 lg:mt-0">
                  {navigation.map((item, idx) => (
                    <li key={idx} className="text-gray-600 hover:text-main">
                      <Link to={item.path} className="nav-link">
                        <a>{item.title}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
                <Header class="mt-5 pt-5 border-t lg:hidden" />
              </div>
              <div className="flex-1 flex items-center justify-end space-x-2 sm:space-x-6">
                {location?.pathname != "/embed" &&
                location?.pathname != "/embed_list" ? (
                  <div
                    className="hidden sm:flex items-center space-x-2 border rounded-md p-2 cursor-pointer hover:bg-slate-50"
                    onClick={(x) => setShowModal(true)}
                  >
                    {/* <div className="hidden sm:flex items-center space-x-2 border rounded-md p-2"> */}
                    <SearchIcon className="w-6 h-6" />
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 flex-none text-gray-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                    <input
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          navigate(location.pathname + "?q=" + searchQuery);
                          setSearchQuery("");
                        }
                      }}
                      className="w-full outline-none appearance-none border-none  placeholder-gray-500 text-gray-500 sm:w-auto"
                      type="text"
                      placeholder="Search"
                    /> */}
                  </div>
                ) : (
                  <div className="flex items-center space-x-2 border rounded-md p-2 w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 flex-none text-gray-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                    <input
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          navigate("/embed_list?q=" + searchQuery);
                          setSearchQuery("");
                        }
                      }}
                      className="w-full outline-none appearance-none border-none  placeholder-gray-500 text-gray-500 sm:w-auto"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                )}
                <Header class="hidden lg:block" />
                {location?.pathname != "/embed" &&
                  location?.pathname != "/embed_list" && (
                    <button
                      className="outline-none text-gray-400 block lg:hidden"
                      onClick={() => setMenuState(!menuState)}
                    >
                      {menuState ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                          />
                        </svg>
                      )}
                    </button>
                  )}
              </div>
            </div>
          </div>
        </nav>
      )}
      {showModal ? (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center flex bg-black/50">
          <div className="relative p-4 w-full max-w-1xl h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Search Document
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Document Type
                    </label>
                    <Select
                      className={"mt-3 border rounded-md " + "border-gray-300"}
                      isClearable
                      // isMulti
                      options={rawCategories}
                      placeholder={`Input Document Type`}
                      components={{ DropdownIndicator: null }}
                      value={searchDocument.category_id}
                      onChange={(values, actionMeta) => {
                        setSearchDocument({
                          ...searchDocument,
                          category_id: values,
                        });
                      }}
                    />
                    {/* <select
                      onChange={(e) => {
                        setSearchDocument({
                          ...searchDocument,
                          category_id: e.target.value,
                        });
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                    >
                      <option value={null} selected>--Pilih salah satu--</option>
                      {rawCategories.map((row) => (
                        <option value={row.id}>{row.category_name}</option>
                      ))}
                    </select> */}
                  </div>

                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      File Name
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setSearchDocument({
                          ...searchDocument,
                          document_title: e.target.value,
                        });
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        "border-gray-300"
                      }
                    />
                  </div>
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Start Date
                    </label>
                    <input
                      type="date"
                      value={searchDocument.date_of_document_start}
                      min={null}
                      onChange={(e) => {
                        setSearchDocument({
                          ...searchDocument,
                          date_of_document_start: e.target.value,
                        });
                        // setSearchDocument({
                        //   ...searchDocument,
                        //   date_of_document_end: e.target.value,
                        // });
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        "border-gray-300"
                      }
                    />
                  </div>
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      End Date
                    </label>
                    <input
                      type="date"
                      value={searchDocument.date_of_document_end}
                      min={searchDocument.date_of_document_start}
                      onChange={(e) => {
                        let d = new Date(e.target.value);
                        d.setMonth(d.getMonth());

                        let date_of_document_start =
                          searchDocument.date_of_document_start
                            ? searchDocument.date_of_document_start
                            : d.getFullYear() +
                              "-" +
                              ("0" + d.getMonth()).slice(-2) +
                              "-" +
                              ("0" + d.getDate()).slice(-2);
                        setSearchDocument({
                          ...searchDocument,
                          date_of_document_end: e.target.value,
                          date_of_document_start,
                        });
                        // setSearchDocument({
                        //   ...searchDocument,
                        //   date_of_document_end: e.target.value,
                        // });
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        "border-gray-300"
                      }
                    />
                  </div>
                </div>
                {/* <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Author
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setSearchDocument({
                          ...searchDocument,
                          author: e.target.value,
                        });
                      }}
                      className={
                        "block w-full p-3 text-gray-900 border rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        "border-gray-300"
                      }
                    />
                  </div>
                </div> */}
                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Practice Group
                    </label>
                    <Select
                      className={"mt-3 border rounded-md " + "border-gray-300"}
                      isClearable
                      isMulti
                      options={practiceGroup}
                      placeholder={`Input Practice Group`}
                      components={{ DropdownIndicator: null }}
                      value={searchDocument.practice_group_id}
                      onChange={(values, actionMeta) => {
                        setSearchDocument({
                          ...searchDocument,
                          practice_group_id: values,
                        });
                      }}
                    />
                  </div>

                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Industry Sector
                    </label>
                    <Select
                      className={"mt-3 border rounded-md " + "border-gray-300"}
                      isClearable
                      isMulti
                      options={industrySector}
                      placeholder={`Input Industry Sector`}
                      components={{ DropdownIndicator: null }}
                      value={searchDocument.industry_sector_id}
                      onChange={(values, actionMeta) => {
                        setSearchDocument({
                          ...searchDocument,
                          industry_sector_id: values,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="flex gap-4 flex-col sm:flex-row">
                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Object
                    </label>
                    <Select
                      className={"mt-3 border rounded-md " + "border-gray-300"}
                      isClearable
                      isMulti
                      options={rawObject}
                      placeholder={`Input Object`}
                      components={{ DropdownIndicator: null }}
                      value={searchDocument.object_id}
                      onChange={(values, actionMeta) => {
                        setSearchDocument({
                          ...searchDocument,
                          object_id: values,
                        });
                      }}
                    />
                  </div>


                  <div className="mb-2 w-full sm:w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Action Type
                    </label>
                    <Select
                      className={"mt-3 border rounded-md " + "border-gray-300"}
                      isClearable
                      isMulti
                      options={actionType}
                      placeholder={`Input Action Type`}
                      components={{ DropdownIndicator: null }}
                      value={searchDocument.action_type_id}
                      onChange={(values, actionMeta) => {
                        setSearchDocument({
                          ...searchDocument,
                          action_type_id: values,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="mb-2 w-full sm:w-1/2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Language
                  </label>
                  <Select
                    isMulti={false}
                    className={
                      "mt-3 border rounded-md "
                    }
                    isClearable
                    options={languages}
                    placeholder={`Select Language`}
                    components={{
                      DropdownIndicator: null,
                    }}
                    value={searchDocument.language}
                    onChange={(values, actionMeta) => {
                      console.log(values);
                      setSearchDocument({
                        ...searchDocument,
                        language: values,
                      });
                    }}
                  />
                      </div>
                <div className="mb-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                    Additional Information
                  </label>
                  <textarea
                    placeholder="(Note: Please insert/input the keywords you feel necessary to make searching easier)"
                    rows="4"
                    onChange={(e) => {
                      setSearchDocument({
                        ...searchDocument,
                        document_briefs: e.target.value,
                      });
                    }}
                    className={
                      "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                      "border-gray-300"
                    }
                  ></textarea>
                </div>

                <div className="flex gap-4 flex-col sm:flex-row ">
                  {/* <div
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="user_avatar_help"
                >
                  A profile picture is useful to confirm your are logged
                  into your account
                </div> */}
                </div>
              </div>
              <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => {
                    setShowModal(false);
                    search();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : // </div>
      null}
    </>
  );
}
