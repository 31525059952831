import React, { useEffect, useState } from "react";
import {
  ArrowSmRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilAltIcon,
  PlusIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { data } from "autoprefixer";

const initPermission = {
  user_code: "",
  allow_login: 1,
  allow_download: 1,
  allow_edit: 0,
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Permission = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const query = useQuery();
  const [type, setType] = useState("add");
  const [showModal, setShowModal] = useState(false);
  const [permissionUsers, setPermissionUsers] = useState([]);
  const [, update] = useState({});
  const [dataPermission, setDataPermission] = useState(initPermission);
  const [savingPermission, setSavingPermission] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageList, setPageList] = useState([]);

  const savePermission = async () => {
    let errorMsg = {};
    if (!dataPermission.user_code) errorMsg.user_code = "user code harus diisi";
    if (Object.keys(errorMsg).length > 0) {
      setErrorMessage(errorMsg);
      return;
    }

    // searchQuery;

    setSavingPermission(true);
    try {
      let permissionSave;
      permissionSave = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/user-permission/`,
        dataPermission,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );

      // setDocuments(getDocuments.data);
      setShowModal(false);
      console.log(permissionSave.data);
      loadPermissions();
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setSavingPermission(false);
      }, 1000);
    }
    console.log(dataPermission);
  };
  const loadPermissions = async () => {
    try {
      setSavingPermission(true);
      const getPermission = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/user-permission?search=${
          query.get("q") ? query.get("q") : ""
        }&limit=${limit}&offset=${(page - 1) * limit}`,
        {
          headers: {
            token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
          },
        }
      );
      setPermissionUsers(getPermission.data.rows);
      let listpage = [];
      for (let i = 0; i < Math.ceil(getPermission.data.count / limit); i++) {
        listpage.push(i + 1);
      }
      setPageList(listpage);
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setSavingPermission(false);
      }, 1000);
    }
  };
  useEffect(() => {
    loadPermissions();
    return () => {};
  }, [query, page, limit]);

  // useEffect(() => {
  //   setCategory(category);
  //   console.log(category);
  // }, [category]);

  return (
    <div>
      <button
        className="z-30 fixed bottom-6 right-6 bg-main py-4 rounded-xl flex items-center px-4 font-medium text-xs  leading-tight  shadow-md  focus:shadow-lg focus:outline-none focus:ring-0  transition duration-150 ease-in-out"
        onClick={(e) => {
          setType("add");
          setDataPermission(initPermission);
          setShowModal(true);
        }}
      >
        <PlusIcon className="w-6 text-white" />
        <div className="text-white font-semibold text-base ml-2">
          Add Permission
        </div>
      </button>

      <div className="text-2xl font-bold mb-4">
        List Permission
        {query.get("q") && (
          <React.Fragment> Search : {query.get("q")}</React.Fragment>
        )}
      </div>
      <div className="grid grid-flow-col gap-4">
        <div className="row-span-2 col-span-2 ">
          <div className="flex flex-row justify-between relative mb-6 ">
            <div className="">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </div>
              <input
                type="search"
                className="block pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                placeholder="Search User Code..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setSearchQuery("");
                    navigate(location.pathname + "?q=" + searchQuery);
                  }
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="mr-2">Tampilkan</label>

              <select
                onChange={(e) => {
                  setLimit(e.target.value);
                }}
                value={limit}
                className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full py-2.5 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    User Code
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Allow Login
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Allow Download
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Allow Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {permissionUsers.map((row, index) => (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {row.user_code}
                      </td>
                      <td className="px-6 py-4 font-extrabold text-gray-900 dark:text-white whitespace-nowrap">
                        <span
                          className={
                            row.allow_login == 1
                              ? "border-2 border-green-500 text-green-500 rounded-xl py-1 px-2"
                              : "border-2 border-red-600 text-red-600 rounded-xl py-1 px-2"
                          }
                        >
                          {row.allow_login == 1 ? "Allowed" : "Not Allowed"}
                        </span>
                      </td>
                      <td className="px-6 py-4 font-extrabold text-gray-900 dark:text-white whitespace-nowrap">
                        <span
                          className={
                            row.allow_download == 1
                              ? "border-2 border-green-500 text-green-500 rounded-xl py-1 px-2"
                              : "border-2 border-red-600 text-red-600 rounded-xl py-1 px-2"
                          }
                        >
                          {row.allow_download == 1 ? "Allowed" : "Not Allowed"}
                        </span>
                      </td>
                      <td className="px-6 py-4 font-extrabold text-gray-900 dark:text-white whitespace-nowrap">
                        <span
                          className={
                            row.allow_edit == 1
                              ? "border-2 border-green-500 text-green-500 rounded-xl py-1 px-2"
                              : "border-2 border-red-600 text-red-600 rounded-xl py-1 px-2"
                          }
                        >
                          {row.allow_edit == 1 ? "Allowed" : "Not Allowed"}
                        </span>
                      </td>
                      <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        <button
                          type="button"
                          className="flex items-center focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 font-medium rounded-lg text-sm px-5 py-2"
                          onClick={(e) => {
                            setShowModal(true);
                            setType("edit");
                            setDataPermission({
                              id: row.id,
                              user_code: row.user_code,
                              allow_login: row.allow_login,
                              allow_edit: row.allow_edit,
                              allow_download: row.allow_download,
                            });
                          }}
                        >
                          <PencilAltIcon className="w-5 mr-1" />
                          Edit
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {pageList.length > 1 && (
            <nav
              aria-label="Page navigation"
              className="p-4 flex justify-center mt-2"
            >
              <ul className="inline-flex items-center -space-x-px cursor-pointer">
                <li>
                  <a
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                      }
                    }}
                    className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <ChevronLeftIcon className="w-5 h-5" />
                  </a>
                </li>
                {pageList.map((x) => (
                  <li>
                    <a
                      onClick={() => {
                        setPage(x);
                      }}
                      className={
                        "py-2 px-3 leading-tight   border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white " +
                        (page == x
                          ? "bg-purple-500 text-white"
                          : "text-gray-500 border bg-white")
                      }
                    >
                      {x}
                    </a>
                  </li>
                ))}

                <li>
                  <a
                    onClick={() => {
                      if (page < pageList.length) {
                        setPage(page + 1);
                      }
                    }}
                    className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <ChevronRightIcon className="w-5 h-5" />
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
      {showModal ? (
        <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-full justify-center items-center flex bg-black/50">
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {type == "add"
                    ? "Add User Permission"
                    : "Edit User Permission"}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="p-6 space-y-6">
                <div className="flex gap-8">
                  <div className="mb-4 w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      User Code
                      {errorMessage?.user_code && (
                        <span className="ml-1 text-xs text-red-600">
                          *{errorMessage.user_code}
                        </span>
                      )}
                    </label>

                    <input
                      type="text"
                      onChange={(e) => {
                        setDataPermission({
                          ...dataPermission,
                          user_code: e.target.value,
                        });
                      }}
                      required
                      value={dataPermission.user_code}
                      className={
                        "block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500 " +
                        (errorMessage?.user_code
                          ? "border-red-600"
                          : "border-gray-300")
                      }
                      disabled={type == "edit" ? true : false}
                    />
                  </div>

                  <div className="mb-4 w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Allow Login
                    </label>
                    <label
                      className={
                        dataPermission.allow_login == 1
                          ? "border-2 border-green-500 text-green-500 rounded-xl  inline-flex items-center cursor-pointer relative w-full"
                          : "border-2 border-red-600 text-red-600 rounded-xl inline-flex items-center cursor-pointer relative w-full"
                      }
                    >
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        onChange={(e) => {
                          console.log(e);
                          setDataPermission({
                            ...dataPermission,
                            allow_login: e.target.checked == true ? 1 : 0,
                          });
                        }}
                        checked={dataPermission.allow_login == 1 ? true : false}
                      />
                      <div
                        className={
                          "-ml-1 w-11 h-6 bg-red-600 rounded-xl peer peer-focus:ring-4 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 " +
                          (dataPermission.allow_login == 1
                            ? "peer-focus:ring-green-300 peer-checked:bg-green-500 after:-ml-1"
                            : "peer-focus:ring-red-300 peer-checked:bg-red-600 ")
                        }
                      ></div>
                      <span
                        className={
                          "ml-2 mr-2 text-sm font-extrabold dark:text-gray-300 " +
                          (dataPermission.allow_login == 1
                            ? "text-green-500"
                            : "text-red-600")
                        }
                      >
                        {dataPermission.allow_login == 1
                          ? "Allowed"
                          : "Not Allowed"}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="flex gap-8">
                  <div className="mb-4 w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Allow Download
                    </label>
                    <label
                      className={
                        dataPermission.allow_download == 1
                          ? "border-2 border-green-500 text-green-500 rounded-xl  inline-flex items-center cursor-pointer relative w-full"
                          : "border-2 border-red-600 text-red-600 rounded-xl inline-flex items-center cursor-pointer relative w-full"
                      }
                    >
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        onChange={(e) => {
                          console.log(e);
                          setDataPermission({
                            ...dataPermission,
                            allow_download: e.target.checked == true ? 1 : 0,
                          });
                        }}
                        checked={
                          dataPermission.allow_download == 1 ? true : false
                        }
                      />
                      <div
                        className={
                          "-ml-1 w-11 h-6 bg-red-600 rounded-xl peer peer-focus:ring-4 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 " +
                          (dataPermission.allow_download == 1
                            ? "peer-focus:ring-green-300 peer-checked:bg-green-500 after:-ml-1"
                            : "peer-focus:ring-red-300 peer-checked:bg-red-600 ")
                        }
                      ></div>
                      <span
                        className={
                          "ml-2 mr-2 text-sm font-extrabold dark:text-gray-300 " +
                          (dataPermission.allow_download == 1
                            ? "text-green-500"
                            : "text-red-600")
                        }
                      >
                        {dataPermission.allow_download == 1
                          ? "Allowed"
                          : "Not Allowed"}
                      </span>
                    </label>
                  </div>
                  <div className="mb-4 w-1/2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">
                      Allow Edit
                    </label>
                    <label
                      className={
                        dataPermission.allow_edit == 1
                          ? "border-2 border-green-500 text-green-500 rounded-xl  inline-flex items-center cursor-pointer relative w-full"
                          : "border-2 border-red-600 text-red-600 rounded-xl inline-flex items-center cursor-pointer relative w-full"
                      }
                    >
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        onChange={(e) => {
                          console.log(e);
                          setDataPermission({
                            ...dataPermission,
                            allow_edit: e.target.checked == true ? 1 : 0,
                          });
                        }}
                        checked={dataPermission.allow_edit == 1 ? true : false}
                      />
                      <div
                        className={
                          "-ml-1 w-11 h-6 bg-red-600 rounded-xl peer peer-focus:ring-4 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 " +
                          (dataPermission.allow_edit == 1
                            ? "peer-focus:ring-green-300 peer-checked:bg-green-500 after:-ml-1"
                            : "peer-focus:ring-red-300 peer-checked:bg-red-600 ")
                        }
                      ></div>
                      <span
                        className={
                          "ml-2 mr-2 text-sm font-extrabold dark:text-gray-300 " +
                          (dataPermission.allow_edit == 1
                            ? "text-green-500"
                            : "text-red-600")
                        }
                      >
                        {dataPermission.allow_edit == 1
                          ? "Allowed"
                          : "Not Allowed"}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={() => {
                    setShowModal(false);
                    setErrorMessage({});
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => {
                    savePermission();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

//
export default Permission;
