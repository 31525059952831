import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ClockIcon,
	DocumentTextIcon,
	UserCircleIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadDocument, dateFormat } from "../libs/common";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import Loader from "../component/Loader";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const EmbedList = () => {
	const [documents, setDocuments] = useState([]);
	const [loadingDocuments, setLoadingDocuments] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const params = useParams();
	const query = useQuery();
	const navigate = useNavigate();
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const [pageList, setPageList] = useState([]);
	// const [downloadHistory, setDownload] = useState([]);

	const loadDocuments = async () => {
		try {
			setLoadingDocuments(true);
			const getDocuments = await axios.get(
				`${
					process.env.REACT_APP_SERVER_URL
				}/document/?limit=${limit}&offset=${(page - 1) * limit}`,
				{
					params: {
						search: query.get("q"),
						tag: query.get("tag"),
						category_id: query.get("category_id"),
					},
				}
			);
			// console.log(getDocuments.data.rows);
			setDocuments(getDocuments.data.rows);
			let listpage = [];
			for (let i = 0; i < Math.ceil(getDocuments.data.count / limit); i++) {
				listpage.push(i + 1);
			}
			// console.log(listpage);
			setPageList(listpage);

			// console.log(getDocuments.data[0].tags);
			// console.log(getDocuments.data);
		} catch (e) {
			console.log(e);
		} finally {
			setTimeout(() => {
				setLoadingDocuments(false);
			}, 1000);
		}
	};

	const loadOneCategory = async () => {
		try {
			const getCategory = await axios.get(
				`${
					process.env.REACT_APP_SERVER_URL
				}/document-category?category_id=${query.get("category_id")}`,
				{
					headers: {
						token: localStorage.getItem("HPRP_DOCUMENT_TOKEN"),
					},
				}
			);
			setSelectedCategory(getCategory.data.rows[0]);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		// console.log(params, query);
		loadDocuments();
		loadOneCategory();
		return () => {};
	}, [params, query, page]);

	return (
		<>
			<div className="flex flex-col">
				<div className="text-lg sm:text-2xl font-bold px-4 flex items-start  mt-4">
					{/* <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 "
              >
                Default
              </button> */}
					<ArrowCircleLeftIcon
						className="w-8 text-green-500 cursor-pointer mr-1"
						onClick={() => {
							navigate("/embed");
						}}
					/>
					{selectedCategory && (
						<React.Fragment>
							List Dokumen {selectedCategory?.category_name}
						</React.Fragment>
					)}
					{params.category &&
						(query.get("q") || query.get("tag")) && (
							<React.Fragment>&nbsp;</React.Fragment>
						)}
					{query.get("q") && (
						<React.Fragment>
							Search : {query.get("q")}
						</React.Fragment>
					)}
					{query.get("tag") && (
						<React.Fragment>
							Tag : {query.get("tag")}
						</React.Fragment>
					)}
				</div>

				{!loadingDocuments &&
					documents.length > 0 &&
					documents.map((row) => (
						<div
							key={row.id}
							className=" m-4 border rounded-lg border-gray-400 "
						>
							<div className="flex justify-between px-4 pt-4">
								<div className="flex">
									<div className="h-6">
										<DocumentTextIcon className="h-6" />
									</div>
									<div
										className="ml-2 text-base sm:text-xl font-semibold text-limit-1 cursor-pointer"
										onClick={() => {
											// dateFormat(row.created_at);
											// navigate(`/document/${row.id}`);
											downloadDocument(
												row.id
											);
										}}
									>
										{row.document_title}
									</div>
								</div>
							</div>
							<div className="text-limit-3 sm:text-base text-sm px-4">
								{row.document_briefs}
							</div>
							<div className="mt-2 text-white px-4 py-3 bg-gray-500 rounded-b-md flex justify-start sm:justify-end gap-4">
								<div className="text-sm sm:text-base flex items-center">
									<UserCircleIcon className="w-6 mr-1" />{" "}
									{row.uploader}
								</div>
								<div className="text-sm sm:text-base flex items-center">
									<ClockIcon className="w-6 mr-1" />{" "}
									{dateFormat(row.created_at)}
								</div>
							</div>
						</div>
					))}
				{!loadingDocuments && pageList.length > 1 && (
					<nav
						aria-label="Page navigation"
						className="p-4 flex justify-center mt-2"
					>
						<ul className="inline-flex items-center -space-x-px cursor-pointer">
							<li>
								<a
									onClick={() => {
										if (page > 1) {
											setPage(page - 1);
										}
									}}
									className="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
								>
									<ChevronLeftIcon className="w-5 h-5" />
								</a>
							</li>
							{pageList.map((x) => (
								<li key={x}>
									<a
										onClick={() => {
											setPage(x);
										}}
										className={
											"py-2 px-3 leading-tight   border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white " +
											(page == x
												? "bg-purple-500 text-white"
												: "text-gray-500 border bg-white")
										}
									>
										{x}
									</a>
								</li>
							))}

							<li>
								<a
									onClick={() => {
										if (page < pageList.length) {
											setPage(page + 1);
										}
									}}
									className="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
								>
									<ChevronRightIcon className="w-5 h-5" />
								</a>
							</li>
						</ul>
					</nav>
				)}
				{!loadingDocuments && documents.length <= 0 && (
					<>
						<img
							src="/empty-recolor.jpg"
							className="w-full sm:w-2/3 mx-auto "
						/>
					</>
				)}
				{loadingDocuments && (
					<div className="flex flex-row items-center justify-center">
						<Loader />
					</div>
				)}
			</div>
		</>
	);
};

export default EmbedList;
